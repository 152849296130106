import { Component, OnInit, TemplateRef } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {CommonService} from "../util/services/common.service";
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {AdminService} from "../admin/admin.service";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit{

  userInfo: any;
  activeProjectId:string;
  activeProjectName:string;
  projectList: any;
  modalRef: BsModalRef;
  constructor( private _authService: AuthService,
               private _route: Router,
               private toastr: ToastrService,
               private modalService: BsModalService,
               private _adminService: AdminService,
               private _commonService: CommonService) {
    this.userInfo =  this._authService.user && this._authService.user
  }

  ngOnInit() {
    this.activeProjectId = localStorage.getItem('projectId');
    this.loadProjectList();
  }

  loadProjectList() {
    this._commonService.getProjectList().subscribe(res=>{
      this.projectList = res;
      this.setActiveProjectName();
    }, err=>{

    })
  }
  setActiveProjectName(){
    let projectList = this.projectList;
    let activeProjectId = this.activeProjectId;
    for (let project of projectList) {
      if (project.id.toString() === activeProjectId.toString()) {
        this.activeProjectName = this.ucfirst(project.value);
       localStorage.setItem('ProjectName', this.activeProjectName);
      }
    }

    if (!this.activeProjectName || this.activeProjectName === '') {
      this.activeProjectName = localStorage.getItem('ProjectName');
    }
  }
  openModal(template: TemplateRef<any>) {
    const config = {
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(template, config);
  }

  confirm(): void {
    this._adminService.updateStatus(this.activeProjectId, true).subscribe(res=>{
      this.toastr.success('Project status updated successfully!');
      this.modalRef.hide();
      this._route.navigate(['/logout']).then(() => {
        window.location.reload()
      });
    }, err=>{

    });

  }

  decline(): void {
    this._route.navigate(['/logout']).then(() => {
      window.location.reload()
    });
    this.modalRef.hide();
  }


  ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
