import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {environment} from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  title: string;
  errMsg:string = '';

  userType: string = 'U';
  roles: Array<Object>;
  currentYear: any;
  selectedRole: string;

  managerOption: boolean;
  modalRef: BsModalRef;

  constructor(private _route: Router,
              private formBuilder: FormBuilder,
              private _authService: AuthService,
              private toastr: ToastrService,
              private modalService: BsModalService) {
    this.title = environment.app.brand.name;
    localStorage.clear();
  }

  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    this.managerOption = environment.app.MANGER__OPTION;
    this.roles = [{
      'key': 'Curator',
      'value': 'Curator',
      'type': 'U'
    },  {
      'key': 'Curator',
      'value': 'Cleanup',
      'type': 'U'
    },{
      'key': 'Admin',
      'value': 'Admin',
      'type': 'M'
    }, {
      'key': 'Manager',
      'value': 'Manager',
      'type': 'M'
    }];

    this.submitted = false;
    this.loading = false;

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]], // Validators.minLength(4)
      role: ['', [Validators.required]],
    });

  }

  onModeChange(mode) {
    this.userType =  mode;
    this.submitted = false;

    //
    //if(this.userType !== 'U'){
    //  this.loginForm.controls["role"].setValidators([Validators.required]);
    //} else this.loginForm.controls["role"].clearValidators();
  }

  onAuthSubmit(template: TemplateRef<any>) {

    let formValue = this.loginForm.value;

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.selectedRole = formValue.role;

    this.loading = true;
    localStorage.clear();
    this._authService.onLogin(this.loginForm.value).subscribe(res => {
      this.loading = false;
      if (formValue.role === 'Curator') {
        this.openModal(template);
      }
      if (formValue.role === 'Cleanup') {
        this.openModal(template);
      } else if (formValue.role === 'Admin') {
        this._route.navigate(['Admin']);

      } else if (formValue.role === 'Manager') {
        this.openModal(template);
      }
    }, err => {
     // console.log(err);
      this.loading = false;
      this.errMsg = err['error'];
    });
  }
  hideErr(){
    this.errMsg = '';
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: true,
      ignoreBackdropClick: true
    });
  }
  hidePopup(evt) {
    if (evt) {
      this.modalRef.hide();
    }
  }
}
