import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaveAsNewService {

  projectId: string;
  header;
  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getEditForm(termId, termType) {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getTermDetails/projectId/'
      + this.projectId + '/termType/' + termType + '/termId/' + termId);
  }

  saveTermAsNew(saveForm) {
    saveForm['projectId'] = this.projectId;
    return this.http.post(environment.app.SERVER__URL + 'lexicon/saveAsNew', saveForm, this.header);
  }
}
