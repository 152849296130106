import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  userInfo: any;
  constructor( private _authService: AuthService) {
    this.userInfo =  this._authService.user && this._authService.user
  }

  ngOnInit() {
  }

}
