import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  @Output() change = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  download() {
    this.change.emit(true);
  }

}
