import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CvManagementService {

  projectId:string;
  header;

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  fetchAllCV(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getprojectCV/' + this.projectId);
  }
  addSource(form) {
    let name = (form['sourceName']) ? form['sourceName'].trim() : '';
    let id = (form['sourceId']) ? form['sourceId'].trim() : '';
    if (name && !id) {
      let param = 'pId=' + this.projectId + '&name=' + name;
      return this.http.post(environment.app.SERVER__URL + 'Manager/addSourceCv', param , this.header);
    }

    if (name && id) {
      let param = `pId=${this.projectId}&newValue=${name}&oldId=${id}`;
      return this.http.post(environment.app.SERVER__URL + 'Manager/editSourceCv', param , this.header);
    }
  }

  deleteCV(cvId) {
    if (cvId) {
      return this.http.get(environment.app.SERVER__URL + 'Manager/deleteProjectCV/' + this.projectId + '/' + cvId);
    }
  }
}
