import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'managerFilter'
})
export class ManagerFilterPipe implements PipeTransform {

  transform(value:Array<any>, args:any[] = null):any {

    let projects = [];

    for (var i = 0; i < value.length; i++) {
      let obj = value[i];


      if (obj) {
        let name = obj['pname'].name;
        let role = obj['role'];

        for (var j = 0; j < role.length; j++) {

          if (role[j] === 'Manager') {
            projects.push(name);
          }
        }

      }
    }


    return projects.join(', ');
  }

}
