import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./auth/login/login.component";
import {AdminComponent} from "./admin/admin.component";
import {AdminDashboardComponent} from "./admin/admin-dashboard/admin-dashboard.component";
import {AddManagerComponent} from "./admin/add-manager/add-manager.component";
import {LogoutComponent} from "./auth/logout/logout.component";
import {ManagerComponent} from "./manager/manager.component";
import {ManagerDashboardComponent} from "./manager/manager-dashboard/manager-dashboard.component";
import {CvManagementComponent} from "./manager/cv-management/cv-management.component";
import {UserManagementComponent} from "./manager/user-management/user-management.component";
import {CuratorComponent} from "./curator/curator.component";
import {CuratorDashboardComponent} from "./curator/curator-dashboard/curator-dashboard.component";
import {VisualizeComponent} from "./curator/visualize/visualize.component";
import {ResetPasswordComponent} from "./auth/reset-password/reset-password.component";
import {ProfileComponent} from "./curator/profile/profile.component";


const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'resetPassword', component: ResetPasswordComponent},
  { path: 'logout', component: LogoutComponent },
  {
    path: '',
    children: [
      {path: '', component: CuratorComponent},
      {path: 'dashboard', component: CuratorDashboardComponent},
      //{path: 'stats', component: StatsComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'visualize', component: VisualizeComponent}
    ],
    component: CuratorComponent
  },

  {
    path: '',
    children: [
      {path: '', component: AdminComponent},
      {path: 'Admin', component: AdminDashboardComponent},
      {path: 'AddMan', component: AddManagerComponent}

    ],
    component: AdminComponent,
  },
  {
    path: '',
    children: [
      {path: '', component: ManagerComponent},
      {path: 'Manager', component: ManagerDashboardComponent},
      {path: 'createCV', component: CvManagementComponent},
      {path: 'AddUser', component: UserManagementComponent},
      //{path: '**', component: PageNotFoundComponent}
    ],
    component: ManagerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, // <-- debugging purposes only
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
