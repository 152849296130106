import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import {NoteService} from "./note.service";
declare var $: any;
import {ToastrService} from "ngx-toastr";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {
  error:any;
  loading:boolean;
  notes: any = {};
  start:number = 0;
  pageNumber:number = 0;
  pages:number = 0;
  limit:number = 5;
  addNewScopeNote:boolean = false;
  scope: any = {
    source: '',
    type: '',
    comment: ''
  }; // new scope add object

  termToDelete: any;
  showConfirmation: boolean = false;
  confirmationMsg: string;
  deleteType: string;


  sourceList: any = [];
  sourceType: any = [];

  @Input()
  formSelected: any;

  @Output()
  change = new EventEmitter<any>();

  modalRef:BsModalRef;
  constructor(private _noteService: NoteService,
              private modalService:BsModalService,
              private toastr:ToastrService) {
  }

  ngOnInit() {
  }

  getNotes() {
    this.loading = true;
    let termId = this.formSelected['key'];
    this._noteService.getNotes(termId, this.start).subscribe(
      result => this.renderNotes(result),
      err => this.errorHandler(err)
    );
  }

  renderNotes(result) {
    this.notes = result[0];
    this.loading = false;
    this.pages = Math.ceil(result[0]['count'] / this.limit);
    this.pageNumber = this.start !== 0 ? (this.start / this.limit) + 1 : 1;
  }


  nextPage() {
    if (this.pageNumber < this.pages) {
      this.start = this.start + this.limit;
      this.getNotes();
    }
  }

  prevPage() {
    if (this.start > 0) {
      this.start = this.start - this.limit;
      this.getNotes();
    }
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
    this.toastr["error"](err['message']);
  }


  addNewNote() {
    this.addNewScopeNote = !this.addNewScopeNote;
    this.scope = {};
    this.getSourceList();
  }

  saveScopeNote(scope:any, event:Event) {
    event.preventDefault();
    let termId = this.formSelected['key'];
    this._noteService.saveScopeNote(termId, scope).subscribe(
      result => this.saveSuccess(result),
      err => this.errorHandler(err)
    );
  }

  saveSuccess(result) {
    if (result['success'] === true) {
      this.toastr["success"](result['message']);
    } else {
      this.toastr["error"](result['message']);
    }

    this.scope = {};
    this.addNewScopeNote = false;

    this.start = 0;
    this.pageNumber = 0;
    this.pages = 0;
    this.getNotes();
  }

  showConfirmationPopup(template: TemplateRef<any>, term) {
    this.termToDelete = term;
    this.deleteType = '';
    this.confirmationMsg = 'Are you sure want to delete this scope note ?';
    this.showConfirmation = true;
    this.openModal(template);
  }
  openModal(template:TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  deleteNote(scope) {
    //let flag = confirm("Are you sure want to delete this scope node ?");
    //
    //if (flag) {
    let termId = this.formSelected['key'];
    this._noteService.deleteScopeNote(termId, scope).subscribe(
      result => this.saveSuccess(result),
      err => this.errorHandler(err)
    );
    //}
  }


   hideConfirmation(bool) {
    this.showConfirmation = false;
    this.modalRef.hide();
  }

   removeNote(approvalObj) {
    this.showConfirmation = false;
    if (approvalObj['isApproved']) {
      this.deleteNote(this.termToDelete);
    }
  }

  getSourceList() {
    this._noteService.getSourceList().subscribe(
      result => this.sourceList = result,
      err => this.errorHandler(err)
    );
  }

  getSourceType(source) {
    if (source === 'default') {
      $('select#type').html('');
    } else {
      this._noteService.getSourceType(source).subscribe(
        result => this.sourceTypeAppend(result),
        err => this.errorHandler(err)
      );
    }
  }

  sourceTypeAppend(result) {
    this.sourceType = result;
    this.scope['type'] = '';

    $('#type').html('');
    $('#type').append($('<option>', {
      value: '',
      text: '--Choose--'
    }));

    for (let i = 0; i < result.length; i++) {
      $('#type').append($('<option>', {
        value: result[i]['value'],
        text: result[i]['value']
      }));
    }
  }

  ngOnChanges(changes) {
    if (changes.formSelected.previousValue !== changes.formSelected.currentValue) {
      this.addNewScopeNote = false;
      if (changes.formSelected.currentValue) {

        this.scope = {};
        this.notes = {};
        this.pageNumber = 0;
        this.pages = 0;
        this.start = 0;
        this.getNotes();
      }
    }
  }



  moreAndLessHandler(evt) {

    let target = $(evt.target);
    if (evt.target.innerText.trim() === "more >>") {
      evt.target.innerText = "less <<";
      target.closest('p').find('.sub-text').removeClass('hidden');
    } else {
      evt.target.innerText = "more >>";
      target.closest('p').find('.sub-text').addClass('hidden');
    }
  }

  moreAndLess(value) {

    if (typeof value === 'string' && value.length) {
      return value.split(' ').length > 20 ;
    }
  }

}
