import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {HistoryService} from "./history.service";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  error: any;
  loading:boolean;
  history: any = [];


  @Input()
  formSelected: any;

  @Output()
  change = new EventEmitter<any>();

  constructor(private _historyService: HistoryService) {
  }

  ngOnInit() {

  }

  getHistory() {
    this.loading = true;
    let termId = this.formSelected['key'];
    this._historyService.getHistory(termId).subscribe(
      result => this.setHistory(result),
      err => this.errorHandler(err)
    );
  }

  setHistory(result) {
    this.loading = false;
    this.history = result;
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
  }

  ngOnChanges(changes) {
    if (changes.formSelected.previousValue !== changes.formSelected.currentValue) {
      if (changes.formSelected.currentValue) {
        this.getHistory();
      }
    }
  }

}
