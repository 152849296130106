import { Component, OnInit, TemplateRef } from '@angular/core';
import {CvManagementService} from "./cv-management.service";
import {NgForm} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cv-management',
  templateUrl: './cv-management.component.html',
  styleUrls: ['./cv-management.component.scss']
})


export class CvManagementComponent implements OnInit {
  strSourceName: string;
  item: any = {};
  //Add Source Object
  objAddSource = {
    preLoader: false,
   // arrRecords: [],
    error: null,
    hasError: false,
    subscribe: {}
  };

  //Fetch All Source Object
  objFetchCV = {
    preLoader: false,
   // arrRecords: [],
    error: null,
    hasError: false,
    subscribe: {}
  };

  //Delete CV
  objDeleteCV = {
    preLoader: false,
  //  arrRecords: [],
    error: null,
    hasError: false,
    subscribe: {}
  };
  loading: boolean;
  deleteItem;
  modalRef: BsModalRef;

  constructor(private _cvService: CvManagementService,
              private toastr: ToastrService,
              private modalService: BsModalService) { }

  ngOnInit() {
    this.onFetchCV();

    this.item = {
      id: '',
      name: ''
    };
  }
  onFetchCV() {
    this.objFetchCV.preLoader = true;
    this.objFetchCV.hasError = false;
    this.objFetchCV.error = null;

    this.objFetchCV.subscribe = this._cvService.fetchAllCV().subscribe(
      res => {
        this.objFetchCV['arrRecords']= res;
        this.objFetchCV.preLoader = false;
      },
      err => {
        this.objFetchCV.preLoader = false;
        this.objFetchCV.hasError = true;
        this.objFetchCV.error = err;
       // this.toastr["error"](err['message']);
      }
    );
  }

  onAddSource(f: NgForm, event: Event) {
    event.preventDefault();

    let val = f.form.value;
    if (val) {
      this.objAddSource.preLoader = true;
      this.objAddSource.hasError = false;
      this.objAddSource.error = null;

      this.objAddSource.subscribe = this._cvService.addSource(val).subscribe(
        res => {
          this.objAddSource['arrRecords'] = res;
          this.objAddSource.preLoader = false;

          if (res['success'] === true) {
            this.toastr["success"](res['message']);


            this.onFetchCV();
          }

          if (res['success'] === false) {
            this.toastr["warning"](res['message']);
          }

          f.resetForm();
        },
        err => {
          this.objAddSource.preLoader = false;
          this.objAddSource.hasError = true;
          this.objAddSource.error = err;
          this.toastr["error"](err['message']);
        }
      );
    }
  }

  onEdit(item) {
    this.item = Object.assign({}, item);
    this.gotoTop();
  }

  openModal(template: TemplateRef<any>, item) {
    this.modalRef = this.modalService.show(template);
    this.deleteItem = item;
  }
  confirm(){
    if (this.deleteItem['name']) {
      this.objDeleteCV.preLoader = true;
      this.objDeleteCV.hasError = false;
      this.objDeleteCV.error = null;

      this.objDeleteCV.subscribe = this._cvService.deleteCV(this.deleteItem['name']).subscribe(
        res => {
          this.objDeleteCV['arrRecords'] = res;
          this.objDeleteCV.preLoader = false;

          if (res['success'] === true) {
            this.toastr["success"](res['message']);
            this.onFetchCV();
          }

          if (res['success'] === false) {
            this.toastr["warning"](res['message']);
          }
        },
        err => {
          this.objDeleteCV.preLoader = false;
          this.objDeleteCV.hasError = true;
          this.objDeleteCV.error = err;
          this.toastr["error"](err['message']);
        }
      );
    }
    this.modalRef.hide()
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
