import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {environment} from '../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../auth.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss']
})
export class ForgetComponent implements OnInit {
  title: string;
  resetForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  @Output() hidePopup = new EventEmitter();

  constructor(private _route: Router,
              private _authService: AuthService,
              private toastr: ToastrService,
              private _formBuilder: FormBuilder) {
    this.title = environment.app.brand.name;
  }

  get f() {
    return this.resetForm.controls;
  }

  ngOnInit() {
    this.submitted = false;
    this.loading = false;

    this.resetForm = this._formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
    });
  }

  onAuthSubmit() {
    this.submitted = true;
    let formValue = this.resetForm.value;

    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    } else {
      this.loading = true;
      this._authService.initiateResetPassword(formValue['username']).subscribe(
        result => {
          this.loading = false;
          if (result['success']) {
            this.toastr['success']('Success', result['message']);
            this.hidePopup.emit(true);
          } else {
            this.toastr['error']('Error', result['message']);
          }

         this.resetForm.reset(formValue);
        },
        err => {
          this.loading = false;
          this.toastr['error']('Error', err['message']);
        }
      );
    }
  }

}
