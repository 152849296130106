import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewFormService {

  projectId: string;
  header;
  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  newTermForm(type, projectId) {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getFormForType/projectId/' +
      this.projectId + '/termType/' + type);
  }

  termUpdate(termId, data_field, value) {
    let data = {};
    let field = {
      [data_field]: value
    };
    data = Object.assign(data, field);

    return this.http.post(environment.app.SERVER__URL + 'lexicon/updateTermDetails/projectId/'
      + this.projectId + '/termId/' + termId, data, this.header);
  }

  getMatchedTerms(termName) {
    let data = {
      "projectId": this.projectId,
      "name" : termName
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/fuzzySearch', data, this.header);

  }

  addNewTerm(newTerm) {
    return this.http.post(environment.app.SERVER__URL + 'lexicon/addNewTerm/', newTerm, this.header);
  }
}
