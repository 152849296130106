import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(public http: HttpClient) { }

  getProject() {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getProjects');
  }

  addProject(user) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
  };
    let param = 'name=' + user.projname ;

    return this.http.post(environment.app.SERVER__URL + 'Manager/addProject', param, options);
  }

  updateStatus(pid , status) {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let param = 'pid=' + pid + "&status=" + status ;
    return this.http.post(environment.app.SERVER__URL + 'Manager/updateStatus', param, options);
  }

  deleteProj(pid) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let param = 'pid=' + pid;
    return this.http.post(environment.app.SERVER__URL + 'Manager/deleteProject', param, options);
  }

  restoreProj(pid) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let param = 'id=' + pid ;
    return this.http.post(environment.app.SERVER__URL + 'Manager/restoreProject', param, options);
  }


  // manager
  getManager() {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getManagers');
  }
  addManager(user) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let param = 'name=' + user.username + '&pass=' + user.password +
      '&proj=' + user.activeproj + '&email=' + user.email;

    return this.http.post(environment.app.SERVER__URL + 'Manager/addManager', param, options);
  }

  saveEditManager(user) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let param = 'name=' + user.username + '&proj=' + user.activeproj + '&email=' + user.email;
    return this.http.post(environment.app.SERVER__URL + 'Manager/editManager', param, options);
  }
  deleteManager(name) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let param = 'name=' + name;
    return this.http.post(environment.app.SERVER__URL + 'Manager/deleteManager', param, options);
  }

  getAutosuggest(keyword){
    return this.http.get(environment.app.SERVER__URL + 'Manager/AllUsers/'+ keyword);
  }

}
