import { Component, OnInit, Input } from '@angular/core';
import {VisualizeService} from "../visualize.service";

export function Debounce(wait: number, immediate: boolean = false) {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    var timeout: any;
    var originalMethod = descriptor.value;
    descriptor.value = function () {
      var context = this;
      var args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          originalMethod.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        originalMethod.apply(context, args);
      }
    };
    return descriptor;
  };
};

interface Status {
  preLoader?: boolean;
  data?: any;
  hasError?: any;
  error?: any;
  sub?: any;
}

@Component({
  selector: 'app-visualize-search',
  templateUrl: './visualize-search.component.html',
  styleUrls: ['./visualize-search.component.scss']
})
export class VisualizeSearchComponent implements OnInit {

  boolShow: boolean;
  keyword: string;
  types: any;
  @Input() param;

  objDetails: Status = {
    preLoader: false,
    data: [],
    hasError: false,
    error: null,
    sub: null
  };

  constructor(private _visualSearchService: VisualizeService) {
    this._visualSearchService.getFields().subscribe(res => {
      if(Array.isArray(res)){
        this.types = res.filter(form => form['attribute'] === 'termType');
      }

    }, err => {

    });
  }

  onShow() {
    this.boolShow = !this.boolShow;
    this.keyword = '';
    this.objDetails.data = [];
  }

  @Debounce(500)
  onkeyup(e) {
    if (this.keyword.length > 0) {
      this.objDetails.preLoader = true;
      this.objDetails.hasError = false;

      if (this.objDetails.sub) {
        this.objDetails.sub.unsubscribe();
      }

      this.objDetails.sub = this._visualSearchService.getTerms(this.keyword).subscribe(res => {
        this.objDetails.preLoader = false;
        this.objDetails.data = res;
      }, err => {
        this.objDetails.preLoader = false;
        this.objDetails.hasError = err;
      });
    } else {
      this.objDetails.data = [];
    }
  }

  onSelect() {
    // this.boolShow = false;
    this.keyword = '';
  }


  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (this.param && this.param['id']) {
      this.boolShow = false;
    } else {
      this.boolShow = true;
    }
  }

  ngOnDestroy() {
    if (this.objDetails.sub) {
      this.objDetails.sub.unsubscribe();
    }
  }

}
