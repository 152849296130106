import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuratorDashboardService {

  projectId: string;
  header;
  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }


  //search result
  getSearchOptions(data, sortOrder) {
    return this.http.post(environment.app.SERVER__URL + 'getSuggestTerm/search/projectId/' + this.projectId + '?sortOrder=' + sortOrder, data, this.header);
  }

  getSearchTypes(data) {
    return this.http.post(environment.app.SERVER__URL + 'getTermTypeInfo/search/termInfo/projectId/' + this.projectId, data, this.header);
  }

  getSearchForm() {
    return this.http.get(environment.app.SERVER__URL + 'getSearchPanel/projectId/'+ this.projectId + '/termType/author');
  }

  getSearchInputAutoSugg(attr, val, termType) {
    if (attr && val && termType) {
      let data = {
        [attr]: val
      };
      return this.http.post(environment.app.SERVER__URL + 'getSuggestTerm/autoSuggestForField/projectId/' +
        this.projectId+ '/termType/' + termType, data, this.header);
    }
  }
  
  getTermAutoSuggest(termData) {

    return this.http.post(environment.app.SERVER__URL + 'getSuggestTerm/search/projectId/' +
      this.projectId, termData, this.header);
  }

  getTermTypes() {
    return this.http.get(environment.app.SERVER__URL + 'project/getTermType/projectId/' + this.projectId);
  }
  getRelationValues() {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getRelationsDetails/projectId/' + this.projectId);
  }
  getTransitiveRelations() {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getPrimaryAndSecondaryRelationInfo/projectId/' + this.projectId);
  }

  getTermColors() {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getTermTypeWithColor/projectId/' + this.projectId);
  }
  setTermStatus(termId, status) {
    if (termId) {

      let data = {
        projectId: this.projectId,
        termId: termId,
        approved: status
      };
      return this.http.post(environment.app.SERVER__URL + 'lexicon/markAsDepricate', data, this.header);
    }
  }
  getAutosuggest(data){
    return this.http.post(environment.app.SERVER__URL + 'getSuggestTerm/search/projectId/' + this.projectId, data, this.header);
  }

}
