import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisualizeService {

  projectId: string;
  header;
  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getChildren(id) {
    // const url = `lexicon/getConceptsDetails/cid/${id}?projectId=${this.projectId}&start=1&offset=6`;
    const url = `lexicon/getConceptRelationsForChart/projectId/${this.projectId}/id/${id}`;
    return this.http.get(environment.app.SERVER__URL + url);
  }

  getRelData(id, rel) {
    const url = `lexicon/getConceptRelationDetailsForChart/projectId/${this.projectId}/id/${id}/relationId/${rel}?start=1&offset=10`;
    return this.http.get(environment.app.SERVER__URL + url);
  }

  getFields() {
    const url = `getSearchPanel/projectId/${this.projectId}/termType/author`;
    return this.http.get(environment.app.SERVER__URL + url);
  }

  getTerms(keyword) {
    let param = [
      {
        "key": "term",
        "value": keyword,
        "dataType": "String"
      }];
    const url = `getSuggestTerm/search/projectId/${this.projectId}`;
    return this.http.post(environment.app.SERVER__URL + url, param, this.header);
  }
}
