import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;
import {ToastrService} from "ngx-toastr";
import {EditFormService} from "./edit-form.service";
import {NewFormService} from "../new-form/new-form.service";
import Common from '../../../util/common';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class EditFormComponent implements OnInit {

  error:any;
  loading:boolean;
  editTerm: any = [];
  type: any = {};
  termStatus:string = '';
  preferred: string = '';
  arrInputTagsHolder: any = [];
  termNameEdited: string = '';

  confirmToAddTerm: boolean = false;
  termName: any;
  actionEvt: any;

  matchedTerms: any = [];

myDatePickerOptions = {
    dateFormat: 'dd-mm-yyyy',
    editableDateField: false
  };

   toggles = {
    value1: '1',
    value2: '0',
    check:true,
    uncheck:false
  };

  @Input() formSelected: any;
  @Input() termTypesArr: any;

  @Output() blobToBeRendered = new EventEmitter<any>();
  @Output() change = new EventEmitter<Object>();
  @Output() termSts = new EventEmitter<Object>();
  constructor( private toastr:ToastrService,
               private _editFormService:EditFormService,
               private _newFormService: NewFormService) { }

  ngOnInit() {
  }
  ngAfterViewInit() {

    setTimeout(() => {
      $('input[data-role=tagsinput], select[multiple][data-role=tagsinput]').on('beforeItemAdd', function(event) {
        let matchFound = false;
        let valuesArray = event.target.value.split(';');
        valuesArray.forEach((val) => {
          if (val.toLowerCase() === event.item.toLowerCase()) {
            matchFound = true;
          }
        });
        if (matchFound) {
          event.cancel = true;
        }
      });
    }, 1000);

  }

  getEditForm() {
    this.loading = true;
    let termId = this.formSelected['key'];
    let termType = this.formSelected['termType'];
    this._editFormService.getEditForm(termId, termType).subscribe(
      result => this.renderEditForm(result),
      err => this.errorHandler('', err)
    );
  }

  renderEditForm(result) {
    let self = this;
    let blobList = [];
    this.termStatus = result['approved'];
    this.preferred = result['preferred'];
    this.editTerm = result['form'];
    this.editTerm.length && this.editTerm.map(term => {
      if (term['type'] === 'mixed') {
        blobList.push(term);
      }
      if (term['type'] === 'text' && term['dataType'] === 'Array') {
        term['value'] = term['value'].join(';');
      }
      if (term['attribute'] === 'name') {
        this.termNameEdited = term['formattedName'];
      }
    });
    this.blobToBeRendered.emit(blobList);

    this.termSts.emit(this.termStatus);
    if (this.termStatus === '-1' && this.formSelected['approved'] === undefined) {
      //Emit to disable other components for readonly
      this.formSelected['approved'] = '-1';
      //this.change.emit(this.formSelected);
    }
    this.loading = false;
    this.error = false;

    if (this.termTypesArr) {
      this.type = this.termTypesArr.find(myObj => myObj['id'] === this.formSelected['termType']);
    }

    result['form'].map((row) => {
      if (row['dataType'] === 'Date') {
        if (row['value'] && /^[0-9-]+$/.test(row['value'])) {
          var split = row['value'].split('-');
          row['value'] = {
            date: {
              year: parseInt(split[2], 10),
              month: parseInt(split[1], 10),
              day: parseInt(split[0], 10)
            }
          };
        } else {
          row['value'] = '';
        }

      }
    });

    let con = this;
    $(function () {

      $("input[data-role=tagsinput], select[multiple][data-role=tagsinput]").tagsinput({
        delimiter: ';',
        confirmKeys: [13],
        trimValue: true
      });

      let tagTimer;
      $('.input-tags').on('itemAdded', function (event) {
        var ele = event.target.dataset.attrname;
        if (ele) {
          let newValue = Common.addInputTag(event, '.edit-Term', ele);

          if (tagTimer) {
            clearTimeout(tagTimer);
          }
          tagTimer = setTimeout(() => {
            for (let i = 0; i < con['editTerm'].length; i++) {
              if (con['editTerm'][i]['attribute'] === ele) {
                con['editTerm'][i]['value'] = newValue;
                let value = con['editTerm'][i]['value'];
                let dataField = con['editTerm'][i]['attribute'];
                //con.onBlurSave(event, value, dataField);
                con.arrayTagsInputManipulation(event, value, dataField);
              }
            }
          }, 2000);

        }
      });


      $('.input-tags').on('itemRemoved', function (event) {
        var ele = event.target.dataset.attrname;
        if (ele) {
          let newValue = Common.removeInputTag(event, '.edit-Term', ele);

          for (let i = 0; i < con['editTerm'].length; i++) {
            if (con['editTerm'][i]['attribute'] === ele) {
              con['editTerm'][i]['value'] = newValue;
              let value = con['editTerm'][i]['value'];
              let dataField = con['editTerm'][i]['attribute'];
              //con.onBlurSave(event, value, dataField);
              con.arrayTagsInputManipulation(event, value, dataField);
            }
          }
        }
      });

      $('.textEditor').each(function () {
        var $textArea = this;
        $($textArea).summernote({
          disableDragAndDrop: true,
          toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['insert', ['specialchars']]
          ]
        });

        Common.summernotePasteResetText($textArea);
        // summernote.focus
        /*  let timer;
         $($textArea).on('summernote.change', function (we, contents, $editable) {
         let ele = we.currentTarget.id;

         if (ele) {

         if (timer) {
         clearTimeout(timer);
         }
         timer = setTimeout(() => {
         for (let i = 0; i < con['editTerm'].length; i++) {
         if (con['editTerm'][i]['attribute'] === ele) {
         con['editTerm'][i]['value'] = contents;
         let value = con['editTerm'][i]['value'];
         let dataField = con['editTerm'][i]['attribute'];
         con.onBlurSave(value, dataField);
         }
         }
         }, 3000);

         }
         });*/


        $($textArea).on('summernote.blur', function (we, contents, $editable) {
          let ele = we.currentTarget.id;

          if (ele) {
            var plainText = $($textArea).summernote("code");
            for (let i = 0; i < con['editTerm'].length; i++) {
              if (con['editTerm'][i]['attribute'] === ele) {
                con['editTerm'][i]['value'] = plainText;
                let value = con['editTerm'][i]['value'];
                let dataField = con['editTerm'][i]['attribute'];
                if (value !== con.termNameEdited) {
                  con.termNameEdited = value;
                  con.getMatchTerms(we, value, dataField);
                  //con.onBlurSave(we, value, dataField);
                }
              }
            }
          }
        });


      });
    });


  }

  errorHandler(evt , err) {

    if (evt.type && evt !== '') {
      $(evt.target).removeClass('field-success');
      $(evt.currentTarget).siblings().removeClass('field-success');
      if (evt.type === 'summernote') {
        $(evt.currentTarget).siblings().addClass('field-error');
      } else {
        $(evt.target).addClass('field-success');
      }
    }

    this.error = err;
    this.loading = false;

    //let message = (err && err['message']) ? err['message'] : ' Something went wrong, Please try again.';

    this.toastr["error"](err['error']['message']);
  }

  ngOnChanges(changes) {
    if (changes.formSelected && changes.formSelected.previousValue !== changes.formSelected.currentValue) {
      if (changes.formSelected.currentValue) {
        this.getEditForm();
        this.error = '';
      }
    }

    if (changes.termTypesArr && changes.termTypesArr.previousValue !== changes.termTypesArr.currentValue) {
      if (this.termTypesArr) {
        this.type = this.termTypesArr.find(myObj => myObj['id'] === this.formSelected['termType']);
      }
    }
  }

  arrayTagsInputManipulation(evt, value, dataField) {
    const tagsInput = value.split(';').map(val => val.trim());
    this.onBlurSave(evt, tagsInput, dataField);
  }

  onBlurSave(evt, value, data_field) {
    let text;
    if (!Array.isArray(value)) {
      value = value.trim();
      text = Common.removeTags(value);
      value = Common.removeNbsp(value);
    }
    if (Array.isArray(value)) {
      value = value.filter((item) => {
        let val = item.trim();
        return val.length > 0;
      });
    }
    if(evt.target && evt.target.type === 'number'){
      value = parseFloat(value);
    }
    if (data_field === 'name' && text && text === '') {
      this.toastr["error"]("Term name can't be empty");
    } else {
      let termId = this.formSelected['key'];
      let termType = this.formSelected['termType'];
      this._editFormService.termUpdate(termId, data_field, value, termType).subscribe(
        result => this.termUpdated(evt, result, data_field, value),
        err => this.errorHandler(evt, err)
      );
    }
  }


  getMatchTerms(evt, value, element) {
    this.loading = true;
    this._newFormService.getMatchedTerms(value).subscribe(
      result => this.showMatchedTerms(evt, result, element, value),
      err => this.errorHandler(evt, err)
    );
  }

  showMatchedTerms(evt, res, element, term) {
    this.loading = false;
    if (res['result'].length) {
      this.matchedTerms = res['result'];
      this.confirmToAddTerm = true;
      this.termName = {termName: term, ele: element};
      this.actionEvt = evt ;
    } else {
      this.onBlurSave(evt, term, element);
    }
  }

  public approvedToAddTerm(termObj) {

    this.onBlurSave(this.actionEvt, termObj['termName'], termObj['ele']);
  }

  onDateChanged(event, data_field) {
    var date = event['formatted'];
    this.onBlurSave(event, date, data_field);
  }

  termUpdated(evt, result, data_field, new_value) {

    $(evt.target).removeClass('field-error');
    $(evt.currentTarget).siblings().removeClass('field-error');
    if (evt.type === 'summernote') {
      $(evt.currentTarget).siblings().addClass('field-success');
    } else {
      $(evt.target).addClass('field-success');
    }

    if (data_field === 'name') {
      this.formSelected['value'] = Common.removeTags(new_value);
      this.formSelected['formatted'] = new_value;
      this.change.emit(this.formSelected);
    }
    this.toastr["success"](result['message']);
  }

}
