import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CommonService} from "../../util/services/common.service";
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {

  @Input() role:String;
  boolLoader:boolean;
  error:any;
  projectSelected;
  projectList: any;
  @Output() hidePopUp:EventEmitter<any> = new EventEmitter<any>();

  constructor(private _commonService: CommonService, private toastr: ToastrService, private _route: Router) { }

  ngOnInit() {
    this.loadProjectList();
  }

  select(project) {
    if (project === '' || project === undefined) {
      //this.toastr.error('', "Please choose project");
      this.error = {message: "Please choose project"};
      return;
    } else {
      this.error = '';
      localStorage.setItem('projectId', project.id);
      this.hidePopUp.emit(true);
      if (this.role === "Curator" || this.role === "Cleanup") {
        this._route.navigate(['dashboard']);
      } else if (this.role === 'Manager') {
        this._route.navigate(['Manager']);
      }
    }
  }

  loadProjectList() {
    this.boolLoader = true;
    this._commonService.getProjectList().subscribe((result:any) =>{
     // this.projectList = result;
      this.boolLoader = false;
      if (result && result.length === 1) {
        let project = result[0];
        this.select(project);
        this.projectList = null;
      } else {
        this.projectList = result;
      }
    }, err=>{
      this.boolLoader = false;
    })
  }

}
