//import * as d3 from 'd3';
declare var d3: any;
declare var transition: any;

export default function drawChart() {
    let data = null;

    function setData(d) {
        data = d;
    }

    function chart(ele, onClickCallback, onLoadMoreCallback) {
        var treeData = data;
        var element = d3.select(ele).node();
        var w = element.getBoundingClientRect().width - 10;
        var h = element.getBoundingClientRect().height - 10;
        var wrapper__length = 220;


        var margin = {left: 50, top: 50, right: 50, bottom: 50},
            width = w + (margin.left - margin.right),
            height = h + (margin.top - margin.bottom);

        // Prepare container

        element.innerHTML = null;

        var zoomer = d3.zoom()
            .scaleExtent([1, 8])
            .on('zoom', onZoomer);

        var offset = 300;

        var svg = d3.select('.chart__galaxy__new')
            .append('svg')
            .attr('width', width)
            .attr('height', height + offset);


        // var names = ['University of Cambridge'];
        //
        // var ul = d3.select('.chart__galaxy__new').append('ul').attr('class', 'breadcrumb__galaxy__new');
        //
        // ul.selectAll('li')
        //   .data(names)
        //   .enter()
        //   .append('li')
        //   .html(String);

        var gh = ((height + offset) / 2);

        // document.querySelector(ele).style.height = (element.getBoundingClientRect().height + offset) + 'px';

        var g = svg.append('g')
            .attr('transform',
                'translate(' + width / 2 + ',' + gh + ') scale(1)');

        function onZoomer() {
            if (d3.event.transform.x < 0 || d3.event.transform.y < 0) {
                d3.event.transform.x = width / 2;
                d3.event.transform.y = height / 2;
            }


            g
                .attr('transform', `translate(${d3.event.transform.x},  	 ${d3.event.transform.y}) scale(${d3.event.transform.k})`);
        }

        var cluster = d3.cluster()
            .separation(function (a, b) {
                return (a.parent === b.parent ? 1 : 2) / a.depth;
            });


        var i = 0,
            root;

        // Get the root
        root = d3.hierarchy(data, function (d) {
            return d.children;
        });

        // Collapse all children
        // Object.keys(this.toilets).forEach(collapse);
        root.children.forEach(collapse);
        root.x0 = height / 2;
        root.y0 = 0;

        // Start drawing
        draw(root);


        function draw(source) {
            cluster.size([2 * Math.PI, height / 2 - (margin.left + margin.right)]);

            // Get treedata. cluster function will return x,y positions of all the nodes in our database
            // if they were to be organized in a tree layout with depths. That's why
            // later we calculate angle and then x,y from angle so that we can make our graph
            // circular

            var treeData = cluster(root);

            var nodes = treeData.descendants(),
                links = treeData.descendants().slice(1);

            var node = g.selectAll('g.node')
                .data(nodes, function (d) {
                    return d.id || (d.id = ++i);
                });

            // Enter the node, draw it at source, so that we can animate later from
            // source


            var nodeEnter = node
                .enter()
                .append('g')
                .attr('class', 'node')
                .on('click', click);

            // Draw a circle with zero radius


            nodeEnter.append('g')
                .attr('transform', 'translate(25,0)')
                .append('text')
                .attr('x', '0')
                .attr('dy', '4.5em')
                .attr('x', function (d) {
                    if (d.depth >= 1) {
                        // return d.x < width / 2 === !d.children ? 20 : 20;
                        return d.x >= Math.PI ? -20 : 20;
                    } else {
                        return 0;
                    }
                })
                .attr('transform', function (d) {
                    if (d.depth >= 1) {
                        return d.x >= Math.PI ? 'rotate(180)' : null;
                    }
                })
                .style('text-anchor', function (d) {
                    if (d.depth < 1) {
                        return 'middle';
                    } else {
                        /// return d.x < width / 2 === !d.children ? "start" : "end";
                        return d.x >= Math.PI ? 'end' : 'start';
                    }
                })
                .attr('class', 'nodeLabel')
                .text(function (d) {
                    if (d.depth === 1) {
                        // return d.data.name || 'N/A';

                        if (d.data.code === undefined) {
                            return d.data.name;
                        } else {

                            if (d.x <= Math.PI) {
                                return d.data.code + ' - ' + d.data.name;
                            } else {
                                return d.data.name + ' - ' + d.data.code;
                            }

                        }
                    }
                })
                .call(wrap, wrapper__length);

            nodeEnter.append('circle')
                .attr('class', 'node');


            //Draw text
            nodeEnter.append('text')
                .attr('class', 'counter')
                .attr('dy', '0.31em')
                .attr('x', function (d) {
                    if (d.depth >= 2) {
                        // return d.x < width / 2 === !d.children ? 20 : 20;
                        return d.x >= Math.PI ? -20 : 20;
                    } else {
                        return 0;
                    }
                })
                .attr('transform', function (d) {
                    if (d.depth >= 2) {
                        return d.x >= Math.PI ? 'rotate(180)' : null;
                    } else if (d.depth === 1) {
                        //Count to make rotate straight
                        return 'rotate(' + -1 * (d.x * 180 / Math.PI - 90) + ')';
                    }
                })
                // .attr("y", function (d) {
                //     return -30;
                // })
                .style('text-anchor', function (d) {
                    if (d.depth <= 1) {
                        return 'middle';
                    } else {
                        /// return d.x < width / 2 === !d.children ? "start" : "end";
                        return d.x >= Math.PI ? 'end' : 'start';
                    }
                })
                .text(function (d) {
                    if (d.depth === 1 && d.children == null) {
                        // return (d._children) ? d._children.length : 0;
                        return (d.data.value) ? d.data.value : 0;
                    } else {
                        if (d.data.code === undefined) {
                            return d.data.name;
                        } else {

                            if (d.x <= Math.PI) {
                                return d.data.code + ' - ' + d.data.name;
                            } else {
                                return d.data.name + ' - ' + d.data.code;
                            }

                        }
                    }
                })
                .call(wrap, wrapper__length);


            var nodeUpdate = nodeEnter.merge(node);

            // When we enter the update phase, change the coordinate of each node using a
            // transition, so that we can animate

            var loader = nodeUpdate.filter(function (d) {
                d3.select('.loader').remove();
                return d.depth === 1 && d.data.value >= 10 && d.children;
            }).append('g')
                .attr('class', 'loader')
                .attr('transform', function (d) {
                    return 'translate(' + (d.y + 220) + ', 0)';
                })
                .on('click', onLoadMoreCallback);

            loader.append('line')
                .attr('stroke', '#ccc')
                .attr('stroke-dasharray', '5')
                .attr('stroke-width', '1')
                .attr('x1', '0')
                .attr('y1', '0')
                .attr('x2', function (d) {
                    return '-' + (d.y + 200);
                })
                .attr('y1', 0);


            loader.append('circle')
                .attr('dy', '0.31em')
                .attr('x', function (d) {
                    return d.x >= Math.PI ? -20 : 20;
                })
                .attr('r', 35)
                .style('fill', '#fff')
                .style('stroke', '#673AB7')
                .style('stroke-width', '2');

            loader
                .append('text')
                .attr('dx', '0em')
                .attr('dy', '0.5em')
                .attr('transform', function (d) {
                    return 'rotate(' + -1 * (d.x * 180 / Math.PI - 90) + ')';
                })
                .style('text-anchor', 'middle')
                .style('fill', '#673AB7')
                .text('View all');


            nodeUpdate.transition()
                .duration(duration)
                .attr('transform', function (d) {
                    if (d.depth < 1) {
                        return 'translate(' + project(d.x, d.y) + ')';
                    } else {
                        return 'rotate(' + (d.x * 180 / Math.PI - 90) + ')' +
                            'translate(' + d.y + ', 0)';
                    }


                });

            // Add color to circle

            nodeUpdate.select('text.counter')
                .attr('transform', function (d) {
                    if (d.depth >= 2) {
                        return d.x >= Math.PI ? 'rotate(180)' : null;
                    } else if (d.depth === 1) {
                        //Count to make rotate straight
                        return 'rotate(' + -1 * (d.x * 180 / Math.PI - 90) + ')';
                    }
                });

            nodeUpdate.select('text.nodeLabel')
                .attr('dy', function (d) {
                    if (d.depth === 1 && d.children !== undefined && d.children !== null) {
                        return (d.children.length && d.children.length % 2 !== 0) ? '-2em' : '0';
                    } else {
                        return '0';
                    }
                })
                .style('font-weight', function (d) {
                    if (d.depth === 1 && d.children !== undefined && d.children !== null) {
                        return 'bold';
                    }
                })
                .attr('fill', function (d) {
                    if (d.depth === 1 && d.children !== undefined && d.children !== null) {
                        return '#3f51b5';
                    } else {
                        return '#000';
                    }
                })
                .on('click', (d) => {
                    d['reCenter'] = true;
                    onClickCallback(d, draw);
                });

            nodeUpdate.select('circle.node')
                .attr('r', function (d) {
                    if (d.depth === 0) {
                        return 60;
                    } else if (d.depth === 1 && d.children == null) {
                        return 35;
                    } else {
                        return d.children ? 15 : 6;
                    }
                })
                .attr('fill', function (d) {
                    if (d.depth <= 2 && (d.data.value === 0
                        || d.data.value === undefined
                        || d.data.isLeafNode === 1)) {
                        return '#eee';
                    } else if (d.depth === 0) {
                        return '#CDDC39';
                    } else if (d.depth === 1 && d.children == null) {
                        return '#FFC107';
                    } else {
                        return d.children ? '#CDDC39' : '#03A9F4';
                    }
                })
                .attr('stroke', function (d) {
                    if (d.depth <= 2 && (d.data.value === 0
                        || d.data.value === undefined
                        || d.data.isLeafNode === 1)) {
                        return '#9E9E9E';
                    } else if (d.depth === 0) {
                        return '#8BC34A';
                    } else if (d.depth === 1 && d.children == null) {
                        return '#FF9800';
                    } else {
                        return d.children ? '#8BC34A' : '#3F51B5';
                    }
                })
                .attr('cursor', 'pointer');

            // Handle exit node, show a transition


            var nodeExit = node.exit().transition()
                .duration(duration)
                .attr('transform', function (d) {
                    return 'translate(' + project(source.x, source.y) + ')';
                })
                .remove();

            // On exit reduce the node circles size to 0
            nodeExit.select('circle')
                .attr('r', 1e-6);

            // On exit reduce the opacity of text labels
            nodeExit.select('text')
                .style('fill-opacity', 1e-6);

            var link = g.selectAll('path.link')
                .data(links, function (d) {
                    return d.id || (d.id = i);
                });

            // We need animation for links also, so we first draw paths whose start and origin is
            // same
            var linkEnter = link.enter().insert('path', 'g')
                .attr('class', 'link')
                .attr('stroke', '#000')
                .attr('id', function (d) {
                    return 'path__id__' + d.id;
                })
                .attr('d', function (d) {
                    var o = {x: source.x0, y: source.y0};
                    return diagonal(o, o);
                });

            var linkUpdate = linkEnter.merge(link);

            // Now animate the links

            linkUpdate.transition()
                .duration(duration)
                .attr('d', function (d) {
                    return diagonal(d, d.parent);
                });

            // Remove any exiting links
            var linkExit = link.exit().transition()
                .duration(duration)
                .attr('d', function (d) {
                    var o = {x: source.x, y: source.y};
                    return diagonal(o, o);
                })
                .remove();

            // Store the old positions for transition.
            nodes.forEach(function (d) {
                d.x0 = d.x;
                d.y0 = d.y;
            });

        }


        function diagonal(s, d) {

            if (d) {
                if (d.depth === 0) {
                    return 'M' + project(s.x, s.y)
                        + 'S' + project(s.x, (s.y + d.y) / 10)
                        + ' ' + project(d.x, (s.y + d.y) / 10);
                } else {
                    return 'M' + project(s.x, s.y)
                        + 'C' + project(s.x, (s.y + d.y) / 2)
                        + ' ' + project(d.x, (s.y + d.y) / 2)
                        + ' ' + project(d.x, d.y);
                }
            }
        }

        function project(x, y) {
            // var angle = (x - 90) / 180 * Math.PI, radius = y;
            // return [radius * Math.cos(angle), radius * Math.sin(angle)];

            return [(y = +y) * Math.cos(x -= Math.PI / 2), y * Math.sin(x)];
        }

        function collapse(d) {
            if (d.children) {
                d._children = d.children;
                d._children.forEach(collapse);
                d.children = null;
            }
        }

        // We use this function, so that all nodes won't be drawn at same time.
        // Play around with this and check it changes the output
        // If you increase the "value", animation will be slower

        function duration(d) {
            var value = 100 + (d.id * 5);
            return value;
        }

        function click(d) {
            if (d.depth !== 0) {

                if (d.children === undefined) {

                    var selected = d;

                    if (selected.parent) {
                        selected.parent.children.forEach(function (element) {
                            if (d !== element) {
                                collapse(element);
                            }
                        });
                    }

                    onClickCallback(d, draw);
                } else {


                    if (d.children) {
                        d._children = d.children;
                        d.children = null;
                    } else {
                        d.children = d._children;
                        d._children = null;
                    }

                    if (d.parent) {
                        d.parent.children.forEach(function (element) {
                            if (d !== element) {
                                collapse(element);
                            }
                        });
                    }
                    d['reCenter'] = false;
                    onClickCallback(d, draw);
                    //draw(d);

                }
            }
        }

        function wrap(text, width) {
            text.each(function () {
                var text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word,
                    line = [],
                    x = text.attr('x'),
                    y = text.attr('y'),
                    dy = 1.2,
                    tspan = text.text(null);

                while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(' '));
                    if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(' '));
                        line = [word];
                        tspan = text.append('tspan')
                            .attr('y', y)
                            .attr('x', x)
                            .attr('dy', dy + 'em')
                            .text(word);
                    }
                }
            });
        }
    }


    function addChildren(selected, arrObj, callback) {
        arrObj && arrObj.map((obj, i) => {
            //Creates new Node
            var newNode = d3.hierarchy(obj);
            newNode.depth = selected.depth + 1;
            newNode.height = selected.height + 1;
            newNode.parent = selected;
            newNode.id = selected.id;

            if (!selected.children) {
                selected.children = [];
                selected.data.children = [];
            }
            selected.children.push(newNode);
            selected.data.children.push(newNode.data);
        });

        callback(selected);
    }

    return {
        setData: setData,
        chart: chart,
        addChildren: addChildren
    };
}
