import { Component, OnInit, TemplateRef } from '@angular/core';
import {ManagerService} from "../manager.service";
import {ToastrService} from "ngx-toastr";
import { NgForm } from '@angular/forms';
import $ from "jquery";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ManagerDashboardComponent implements OnInit {

  loading:boolean;
  autoSuggestFilter:Array<any> = [];
  autoSuggestTermType:Array<any> = [];
  type_added:boolean;
  editedIndex;

  user:any = {};

  relObj:any = {};
  termTypeObj:any = {};
  termAttObj:any = {};
  cvManageObj:any = {};
  mapObj:any = {};
  searchObj:any = {
    termType: '',
    ui_name: '',
    dis_type: ''
  };
  confirmObj:any = {};
  uniqe:string = '';

  editTerm:boolean;
  autoComplete:boolean = false;
  dataLoading:boolean = false;
  timer;
  i;

  relations;
  colorList;
  termTypesAll;
  exRelTerm;
  exRelSearch;
  mappingList;
  AttrList;
  searchList;
  FinalConfig;

  showRegexModal:boolean = false;
  templateId:string = '';
  confirmationMsg:string = '';
  isOverriden:boolean = false;
  showConfirmation:boolean = false;
  isConfiguredProject:boolean = false;
  genericTemplates:any;
  configuredProject:any = {};
  indexBlockObject:any = {};

  termType:any = {};
  termTypeId:string = '';
  activeProjectName:string = '';
  termTypeAction:string = 'create';
  attrList:any = [];
  termInputType:string = '';
  isEdit:boolean = false;
  editingIndex:number = 0;
  newtermType:boolean = false;
  indexArrayEmpty:boolean = false;
  isTermTypeSaved:boolean = false;
  descriptionError:boolean = false;
  seqIdTypeError:boolean = false;

  deleteId: any;
  deleteType: any;

  modalRef:BsModalRef;

  colors;


  constructor(private _managerService:ManagerService,
              private toastr:ToastrService,
              private modalService:BsModalService) {
    localStorage.setItem('lastRoute', 'Manager');
  }

  ngOnInit() {
    this.type_added = false;
    this.setDefaultObjects();
    this.getProjectStatus();
    this.colors = [
      "#00FFFF",
      "#000000",
      "#0000FF",
      "#D2691E",
      "#008B8B",
      "#D50000",
      "#006400",
      "#8B008B",
      "#FF8C00",
      "#9400D3",
      "#FF1493",
      "#696969",
      "#228B22",
      "#4B0082",
      "#800000",
      "#9370DB",
      "#000080"];
  }

  onChange(value) {
    this.termTypeObj.color = value;
  }

  getProjectStatus() {

    this._managerService.getProjectConfigStatus().subscribe(res => {
      this.isConfiguredProject = res['configured'];
      this.i = this.isConfiguredProject ? 8 : 1;
      this.nextTab('startConf');
    }, err => this.errorHandler(err));
  }

  getGenericTemplates() {
    this._managerService.getGenericTemplates().subscribe(res => {
      this.genericTemplates = res;
    }, err => this.errorHandler(err));
  }

  getProjectDetails() {
    this.activeProjectName = localStorage.getItem('ProjectName');
    this._managerService.getProjectDetails().subscribe(res => {
      const isSkos = res['isSkos'];
      this.configuredProject = {
        skos: isSkos ? 'skos' : 'nonSkos',
        description: res['description'],
        seqIdType: res['seqIdType'],
        projectShortForm: res['projectShortForm'] || ''
      };
    }, err =>this.errorHandler(err));
  }

  getRelations() {
    this.dataLoading = true;
    this._managerService.getRelation().subscribe(
      result => this.submitHandler('relations', result),
      err => this.errorHandler(err)
    );
  }

  submitHandler(type, result) {
    if (type === 'relations') {
      this.relations = result;
      this.dataLoading = false;

    } else if (type === 'colors') {
      this.colorList = result;

    } else if (type === 'termtypesAll') {

      if (result.length >= 1) {
        this.type_added = true;
        this.termTypesAll = result;
        this.dataLoading = false;
      } else {
        this.type_added = false;
        this.termTypesAll = result;
        this.dataLoading = false;
      }
    } else if (type === 'getExpRelsFor') {
      this.exRelTerm = result;

    } else if (type === 'getExpRelsBin') {
      this.exRelSearch = result;

    } else if (type === 'mapping') {
      this.mappingList = result;
      this.dataLoading = false;

    } else if (type === 'AttrByTerm') {
      this.AttrList = result.property;
    } else if (type === 'termTypeExp') {

      this.isTermTypeSaved = true;
      this.attrList = result.property;
      this.termTypeObj.expRel = result.expectedRels;
      this.termTypeObj.color = result.color;
      this.editTerm = true;

      //$(document).ready(function () {
      //  $('[name="dis_col"]').data('paletteColorPickerPlugin').reload();
      //
      //});

    } else if (type === 'SearchParam') {

      this.searchList = result;
      this.dataLoading = false;

    } else if (type === 'FinalConfig') {

      this.FinalConfig = result;
      this.confirmObj = {};
      this.confirmObj.description = result.description;
      this.confirmObj.seqIdType = result.seqIdType;
      this.confirmObj.uniqueField = result.uniqueField;
      this.confirmObj.projectShortForm = result.projectShortForm;
      this.dataLoading = false;

    }

  }

  changeProjectStatus() {

    this._managerService.changeProjStatus(0).subscribe(
      result => this.submitHandler('ProjStatus', result),
      err => this.errorHandler(err)
    );
  }


  getTermtypesAll() {
    this.dataLoading = true;
    this._managerService.getTermtypesAll().subscribe(
      result => this.submitHandler('termtypesAll', result),
      err =>this.errorHandler(err)
    );
  }

  getMappings() {

    this.mapObj = {};
    this.exRelTerm = [];
    this.exRelSearch = [];
    this.dataLoading = true;
    this._managerService.getMappings().subscribe(
      result => this.submitHandler('mapping', result),
      err =>this.errorHandler(err)
    );
  }

  getAllSearchParams() {
    this.dataLoading = true;
    this._managerService.getAllSearchParams().subscribe(
      result => this.submitHandler('SearchParam', result),
      err => this.errorHandler(err)
    );
  }

  getExistingIndexes() {
    this._managerService.getIndexedItems().subscribe(res => {
      this.indexBlockObject['indexList'] = res['indexList'].map(name => name.split('#').join(','));
      this.indexBlockObject['propertyList'] = res['propertyList'];
    }, err => this.errorHandler(err));
  }

  getConfigView() {
    this.dataLoading = true;
    this._managerService.getConfigView().subscribe(
      result => this.submitHandler('FinalConfig', result),
      err => this.errorHandler(err)
    );
  }

  nextTab(rel) {
    this.dataLoading = false;
    switch (rel) {
      case 'startConf':
        this.i = 1;
        this.getGenericTemplates();
        this.getProjectDetails();
        break;
      case 'relation':
        this.i = 2;
        this.getRelations();
        this.changeProjectStatus();
        break;
      case 'termType':
        this.i = 3;
        this.getTermtypesAll();
        this.getRelations();
        this.changeProjectStatus();
        break;
      case 'relationShips':
        this.i = 4;
        this.getMappings();
        this.changeProjectStatus();
        break;
      case 'searchPanel':
        this.i = 5;
        this.getTermtypesAll();
        this.getAllSearchParams();
        this.changeProjectStatus();
        break;
      case 'indexes':
        this.i = 6;
        this.getExistingIndexes();
        this.changeProjectStatus();
        break;
      case 'finalconf':
        this.i = 7;
        this.getConfigView();
        this.changeProjectStatus();
        break;
    }
    this.setRespectiveTab(rel);
  }

  setDefaultObjects() {

    this.configuredProject = {
      skos: '',
      description: '',
      seqIdType: '',
      projectShortForm: ''
    };
    this.termAttObj = {
      displayName: null,
      inputType: null,
      dataType: null,
      mandatory: null
    };

    this.user = {
      indexTerm: []
    };

    this.termType = {
      createUpdate: 'createTerm'
    };
  }

  errorHandler(err) {
    this.loading = false;
    this.toastr.error('Error<br>' + err.message);
  }

  setRespectiveTab(tabId) {
    $('.tab-pane').removeClass('active');
    $('#' + tabId).addClass('active');
    $('.wizard-steps li').removeClass('active');
    $('.wizard-steps').find('.' + tabId).addClass('active');
  }

  navigationController(navTab, index) {
    if (this.isConfiguredProject || index <= this.i) {
      this.nextTab(navTab);
    }
  }

  startConfiguration(formValue) {
    if (!formValue['projDesc'] || formValue['projDesc'] === '') {
      this.toastr['error']('Please enter Project description!');

    } else if (!formValue['seqIdType'] || formValue['seqIdType'] === '') {
      this.toastr['error']('Please enter Sequence ID Type!');
    } else {
      const isSkos = formValue['skos'] === 'skos';
      this._managerService.setProjectDetails(formValue['projDesc'], formValue['seqIdType'], isSkos, formValue['projectShortForm']).subscribe(res => {
        this.nextTab('relation');
      }, err => this.errorHandler(err));
    }
  }

  genericTemplateHandler(form, template:TemplateRef<any>) {

    const _self = this;
    $(function () {
      var value = $('#templateId').val();

      var projID = $('#browsers [value="' + value + '"]').data('value');

      _self.templateId = projID;
      _self.isConfiguredProject ? _self.configurationConfirmation(template) : _self.configurationWithTemplate(false);
    });


  }

  configurationConfirmation(template) {

    this.confirmationMsg = 'This project already configured,\
                Are you sure want to configure again?';
    this.showConfirmation = true;
    this.openModal(template);
  }

  openModal(template:TemplateRef<any>, id?, type?) {
    this.modalRef = this.modalService.show(template);
    if (id) this.deleteId = id;
    if (type) this.deleteType = type;

  };

  hidePopup(evt) {
    if (evt) {
      this.modalRef.hide();
    }
  }

  configurationWithTemplate(isOverride) {
    this._managerService.genericTemplate(this.templateId, isOverride).subscribe(res => {
      this.toastr.success('Configured successfully!');
      this.getProjectStatus();
      //this.getProjectDetails();
    }, err => this.errorHandler(err));
  }

  setAutoSuggest(result) {
    $('ul.autoManager').show();
    this.autoSuggestFilter = result;
  }

  //Autocomplete
  AutoSuggestRelation(event:any) {

    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      if (event.target.value.length) {
        //Service Call
        this.autoComplete = true;


        this._managerService.getAutoSuggestRelations(event.target.value).subscribe(
          result => this.setAutoSuggest(result),
          err => this.errorHandler(err)
        );
      }
    }, 400);

  }

  autoSuggestSelection(key, value) {
    this.relObj.term = value;
    this.autoComplete = false;
  }

  saveRelation() {
    this._managerService.saveRelation(this.relObj).subscribe(
      result => this.submitHandlerSaveRel(result, 'saved'),
      err => this.errorHandlerSaverel(err)
    );
  }

  submitHandlerSaveRel(result, option) {
    this.editedIndex = -1;
    this.getRelations();
    this.loading = false;
    $("#relform").trigger('reset');
    if (result.success) {
      this.toastr['success'](`Relation ${option} successfully!`);
    } else {
      this.toastr['error']('Error adding relation! \n' + result.message);
    }
  }

  errorHandlerSaverel(err) {
    this.getRelations();
    this.loading = false;
    this.toastr.error('Error adding relation!');
  }

  editRelation(val, id) {
    val = val.trim();

    if (/^[a-zA-Z]+[a-zA-Z ]*$/.test(val)) {
      this._managerService.editRelation(val, id).subscribe(
        result => this.submitHandlerSaveRel(result, 'edited'),
        err => this.errorHandlerSaverel(err)
      );
    } else {
      this.toastr.warning('Enter proper term (only letters)');
    }
  }

  confirmDelete() {
    if (this.deleteType == 'relation') {
      this._managerService.deleteRelation(this.deleteId).subscribe(
        result => {
          this.getRelations();
          if (result['success']) {
            this.toastr.success('Relation Deleted!');

          } else {
            this.toastr.error('Error deleting relation!\n' + result['message']);
          }
        },
        err => {
          this.getRelations();
          this.toastr.error('Error deleting relation!');
        }
      );
    }
    if (this.deleteType == 'termType') {
      this.deleteTermType(this.deleteId);
    }
    if (this.deleteType == 'relationship') {
      this.deleteMap(this.deleteId);
    }
    if (this.deleteType == 'search'){

      this._managerService.deleteSearchPar(this.deleteId).subscribe(
        result => {
          this.getAllSearchParams();
          this.toastr.success('Search Parameter Deleted!');
        },
        err => {
          this.getAllSearchParams();
          this.toastr.error('Error deleting search parameter!');
        }
      );

    }
    this.modalRef.hide()
  }

  // termtype

  toggleTermTypeBlock(option) {
    this.termTypeAction = option;
    this.isTermTypeSaved = false;
    this.termTypeId = null;
    this.attrList = [];
    this.resetForm();
  }

  resetForm() {
    this.editTerm = false;
    this.termAttObj.termType = null;
    $("#AddTermTypeform").trigger('reset');
    $(document).ready(function () {
      //$('[name="dis_col"]').data('paletteColorPickerPlugin').reload();
    });
  }

  AutoSuggestTermTypes(event:any) {

    if (this.termTypeAction === 'update') {

      $(event.target).removeClass('field-error');
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if (event.target.value.length) {
          //Service Call
          this.autoComplete = true;
          this._managerService.getAutoSuggestTermTypes(event.target.value).subscribe(
            result => this.setAutoSuggestTermtypes(result),
            err => this.errorHandler(err)
          );
        }
      }, 400);
    }
  }

  setAutoSuggestTermtypes(result) {

    $('ul.autoManager').show();
    this.autoSuggestTermType = result;
  }

  autoSuggestSelectionTermTypes(value) {

    this.termTypeObj.termName = value.name;
    this.autoComplete = false;
    this.termAttObj.termType = value.id;
    this.termTypeObj.id = value.id;
    this.termTypeId = value.id;

    this._managerService.getAttrTermType(value.id).subscribe(
      result => this.submitHandler('termTypeExp', result),
      err => this.errorHandler(err)
    );

  }

  saveTermType() {

    if (this.termTypeAction === 'update') {
      this._managerService.saveEditedTermType(this.termTypeObj).subscribe(
        result => {
          this.loading = false;
          if (result['success']) {
            this.getTermtypesAll();
            this.toastr.success('Term Type updated!');

          } else {
            this.toastr.error('Error updating  Term Types!\n' + result['message']);
          }
        },
        err => {
          this.getTermtypesAll();
          this.loading = false;
          this.toastr.error('Error adding Term Types!');
        }
      );
    } else {
      this._managerService.saveTermType(this.termTypeObj).subscribe(
        result => {
          this.termTypeId = result['termId'];

          this.loading = false;

          if (result['success']) {
            this.toastr['success']('Term Types added!');
            this.newtermType = true;
            this.isTermTypeSaved = true;
            this.getTermtypesAll();

          } else {
            this.toastr.error('Error adding  Term Types!\n' + result['message']);
          }
        },
        err => {
          this.getTermtypesAll();
          this.loading = false;
          this.toastr.error('Error adding Term Types!');
        }
      );
    }
  }

  addAttribute(form:NgForm, attrObj) {

    let attrExist = false;
    const attributeName = attrObj['display'].split(' ').map(word => word.trim()).join('').toLowerCase();
    //const attributeName = attrObj['display'].toLowerCase();

    if (attributeName === 'name' || attributeName === 'id' || attributeName === 'cid') {
      this.toastr['error']('Attribute name should not be reserved name!');
    } else {
      this.attrList.forEach(attr => {
        if (attr['display'].toLowerCase() === attributeName) {
          attrExist = true;
        }
      });

      this.attributeActionForInsertion(attrExist, attrObj, form);
    }
  }

  attributeActionForInsertion(attrExist, attrObj, form) {
    if (!attrExist) {
      this.addAttributeToList(attrObj);
      form.resetForm();
      this.setDefaultObjects();
    } else {
      this.toastr['error']('Attribute already present!');
    }
  }

  addAttributeToList(attrObj) {

    if (!this.isEdit) {
      this.isEdit = !this.isEdit;
    }
    attrObj['mandatory'] = attrObj['mandatory'] === null ? false : attrObj['mandatory'];
    if (this.isEdit) {
      this.isEdit = false;
      //this.attrList.push(attrObj);
    } //else {
    this.attrList.push(attrObj);
    // }
  }

  saveTermAttributes() {

    this._managerService.saveTermAttributes(this.attrList, this.termTypeId).subscribe(
      result => this.submitHandlerSaveTermAtt(result),
      err => {
        this.loading = false;
        this.getTermtypesAll();
        this.toastr.error('Error adding Attribute!');
      }
    );
  }

  submitHandlerSaveTermAtt(result) {

    this.loading = false;
    this.getTermtypesAll();

    this.toggleTermTypeBlock(this.termTypeAction);
    this.setDefaultObjects();

    if (result.success) {

      const action = this.termTypeAction === 'create' ? 'added' : 'updated';
      this.toastr['success'](`Attributes ${action} successfully!`);
    } else {
      this.toastr['error']('Error adding Attribute!<br>' + result.message);
    }

  }

  attributeOperation(index, operation, attrName?) {
    if (operation === 'edit') {
      if (!this.isEdit) {
        const tempObj = this.attrList[index];
        this.termAttObj = tempObj;
        this.termInputType = tempObj['type'];
        this.attrList.splice(index, 1);
        this.isEdit = true;
        this.editingIndex = index;
      }
    } else {
      if (this.termTypeAction === 'create') {
        this.toastr['success']('Attribute deleted successfully!');
      } else {
        this.deleteTermAttribute(this.termTypeId, attrName, index);
      }
    }
  }

  deleteTermAttribute(id, name, index) {

    this._managerService.deleteTermAttribute(id, name).subscribe(
      result => {
        this.submitHandlerdelTermAtt(result);
        this.attrList.splice(index, 1);
      }, err => {
        this.getTermtypesAll();
        this.toastr.error('Error deleting Attribute!');
      }
    );
  }

  submitHandlerdelTermAtt(result) {
    this.getTermtypesAll();
    if (result['success']) {
      this.toastr['success']('Attribute deleted successfully!');
    } else {
      this.toastr['error']('Error deleting Attribute!');
    }
  }

  deleteTermType(id) {
    this._managerService.deleteTermType(id).subscribe(
      result => this.submitHandlerdelTermType(result),
      err => {
        this.getTermtypesAll();
        this.toastr.error('Error deleting Term Types!');
      }
    );
  }

  submitHandlerdelTermType(result) {
    this.getTermtypesAll();
    //this.getunAssignCorrRels();


    if (result['success']) {
      this.toastr.success('Term Type Deleted!');

    } else {
      this.toastr.error('Error deleting Term Type!<br>' + result.message);

    }

  }


  // relationships

  onTermtype(val) {

    if (val === '') {
      this.mapObj.bin_for_term = '';
    } else if (val === 'ALL') {

      this._managerService.getExpRels(val).subscribe(
        result => this.submitHandler('getExpRelsFor', result),
        err => this.errorHandler(err)
      );
      this.mapObj.bin_for_term = 'ALL';

    } else {

      let obj = val.split(';');
      this._managerService.getExpRels(obj[0]).subscribe(
        result => this.submitHandler('getExpRelsFor', result),
        err => this.errorHandler(err)
      );
      this.mapObj.bin_for_term = obj[1];
    }
  }

  onSearchtype(val) {

    if (val === '') {
      this.mapObj.bin_for_search = '';
    } else if (val === 'ALL') {
      this._managerService.getExpRels(val).subscribe(
        result => this.submitHandler('getExpRelsBin', result),
        err => this.errorHandler(err)
      );

      this.mapObj.bin_for_search = 'ALL';
    } else {
      let obj = val.split(';');
      this._managerService.getExpRels(obj[0]).subscribe(
        result => this.submitHandler('getExpRelsBin', result),
        err => this.errorHandler(err)
      );

      this.mapObj.bin_for_search = obj[1];
    }
  }

  AddMapping(formData) {

    this._managerService.AddMapping(formData).subscribe(
      result => this.submitHandlerSaveMap(result),
      err => {
        this.loading = false;
        this.getMappings();
        $("#Mappingform").trigger('reset');

        this.toastr.error('Error adding Relation Mapping!');
      }
    );
  }

  submitHandlerSaveMap(result) {

    this.loading = false;
    this.getMappings();
    $("#Mappingform").trigger('reset');


    if (result['success']) {
      this.toastr.success('Relation Mapping added!');
    } else {
      this.toastr.error('Error adding Relation Mapping!<br>' + result.message);
    }


  }

  deleteMap(id) {
    this._managerService.deleteMap(id).subscribe(
      result => this.submitHandlerdeleteMap(result),
      err => {
        this.getMappings();
          this.toastr.error('Error deleting Mapping!');
      }
    );
  }
  submitHandlerdeleteMap(result) {
    this.getMappings();
    this.loading = false;

    if (result['success']) {
        this.toastr.success('Mapping Deleted!');
    } else {
        this.toastr.error('Error deleting Mapping!<br>' + result.message);

    }

  }

  //Search Panel

  onTermtypeSearch(val) {

    if (val === '') {
    } else if (val === 'id') {
      this.searchObj.ui_name = 'id';
      this.searchObj.dis_type = 'multiInput';
    } else {

      this._managerService.getAttrTermType(val).subscribe(
        result => this.submitHandler('AttrByTerm', result),
        err => this.errorHandler(err)
      );
    }
  }

  SaveSearch() {
    this._managerService.SaveSearch(this.searchObj).subscribe(
      result => this.submitHandlerSaveSearch(result),
      err => {

        this.loading = false;
        this.getAllSearchParams();
        $("#searchform").trigger('reset');
        this.toastr.error('Error adding Search Parameter!');
      }
    );
  }

  submitHandlerSaveSearch(result) {

    this.loading = false;
    this.getAllSearchParams();
    $("#searchform").trigger('reset');


    if (result['success']) {
        this.toastr.success('Search Parameter added!');

    } else {
        this.toastr.error('Error adding Search Parameter!');

    }

  }


  // index panel

  createIndexing(formValue) {
    this.indexArrayEmpty = false;
    const attrArray = formValue['indexAttr'];
    if (attrArray.length) {
      const attrList = attrArray.join(',');
      this._managerService.createProjectIndex(attrList).subscribe(res => {
        this.saveSuccess(res);
        this.getExistingIndexes();
        this.setDefaultObjects();
      }, err => this.errorHandler(err));
    } else {

      this.indexArrayEmpty = true;
    }
  }

  saveSuccess(result) {
    if (result['success']) {
      this.toastr.success(result['message']);
    } else {
      this.toastr.error(result['message']);
    }
  }

  deleteIndexedTerm(indexTerm) {
    this.loading = true;
    this._managerService.deleteIndexedTerm(indexTerm).subscribe(
      result => {
        this.loading = false;
        this.toastr.success(result['message']);
        this.getExistingIndexes();
      },
      err => {
        this.loading = false;
        this.toastr.success(err['message']);
      }
    );
  }
  activateProject() {
    this._managerService.confirmConfig().subscribe(
      result => this.submitHandlerConfirmConfig(result),
      err => {
        this.loading = false;
        this.toastr.error('Error saving Configuration file!');
      }
    );
    this.modalRef.hide();
  }

  submitHandlerConfirmConfig(result) {
    this.loading = false;
    this.getConfigView();
    if (result['success']) {
        this.toastr.success('Configuration saved successfully!');

    } else {
        this.toastr.error('Error creating Configuration!<br>' + result.message);

    }

  }

}
