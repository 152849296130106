import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rolefilter'
})
export class RolefilterPipe implements PipeTransform {

  transform(items:any[], args:any[]):any {
    return items.filter(item => item.type.indexOf(args[0]) !== -1);
  }

}
