import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from "../auth.service";
import {ToastrService} from "ngx-toastr";
import $ from "jquery";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  error:any;
  loading:boolean;
  authTokenString:string = '';
  currentYear;

  constructor( private toastr: ToastrService,
               private route:ActivatedRoute,
               private router:Router,
               private _resetPassword: AuthService,
               private _formBuilder: FormBuilder) {

    localStorage.setItem('lastRoute', 'resetPassword');
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.authTokenString = params['token'];
      });
  }

  ngAfterViewInit() {
  }

  submitForm(value:any, evt):void {
    if (value['password'] === value['cnpassword']) {
      this.loading = true;

      this._resetPassword.updatePassword(value).subscribe(
        result => {
          this.loading = false;
          //this.toastr['success']('Success', 'Reset Password successfully');

          if (result['success']) {
            this.toastr['success']('Success', result['message']);

          } else {
            this.toastr['error']('Error', result['message']);
          }
          $('#passwordUpdate')[0].reset();
          //this.router.navigate(['login']);
        },
        err => this.errorHandler(err)
      );
    } else {
      this.toastr["error"]("Confirm password doesn't match.");
    }
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;

    this.toastr["error"]('Error', err['error']['message']);
  }

}
