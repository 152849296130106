import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {TreeService} from "./tree.service";
import $ from "jquery";

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TreeComponent implements OnInit {

  error:any = '';
  loading:boolean = false;
  nodeTree: any = {};

  @Input()
  nodeKey: any = {};
  @Input()
  relationValues:Array<string>;

  @Output()
  formSelected: EventEmitter<any> = new EventEmitter<any>();
  constructor(private _treeService: TreeService) { }

  ngOnInit() {
    this.nodeTree = this.nodeKey;
  }

  ngAfterViewInit() {
    let com = this;
    $("ul.lex-tree-structure").on("click", "i.termView", function (e) {
      e.preventDefault();
      let element = this;
      let termId = $(element).attr("data-termId");
      com.loading = true;
      let nodeInfo = com._treeService.getNodeInfo(termId).subscribe(
        result => com.renderTreeNodes(result, element),
        err => com.errorHandler(err)
      );
    });

    $("ul.lex-tree-structure").on("click", "i.relationView", function (e) {
      e.preventDefault();
      let element = this;
      let termId = $(element).attr("data-termId");
      let rId = $(element).attr("data-rId");
      let termType = $(element).attr("data-termType");
      com.loading = true;
      if (com.relationValues && com.relationValues[termType]['relations'][rId] !== "") {
        let relationValue = com.relationValues[termType]['relations'][rId];
        let nodeInfo = com._treeService.getNodeRelationInfo(termId, rId, termType, relationValue).subscribe(
          result => com.renderTreeTerms(result, element),
          err => com.errorHandler(err)
        );
      }

    });

    $("ul.lex-tree-structure").on("click", "span.viewSelector", function (e) {
      e.preventDefault();
      let key = $(this).attr("data-key");
      let value = $(this).attr("data-value");
      let termType = $(this).attr("data-termType");
      com.viewSelector(key, value, termType);
    });

    $("a.full-view-toggle").click(function () {
      $(this).parent().toggleClass("full-view");
    });
  }

  treeRoot() {
    let element = $('#root');
    let termId = this.nodeKey['key'];
    this.loading = true;
    this._treeService.getNodeInfo(termId).subscribe(
      result => this.renderTreeNodes(result, element),
      err => this.errorHandler(err)
    );
  }

  renderTreeTerms(result, element) {
    let li = $(element).closest('li');
    let label = $(element).closest('label');
    let nodes = result;

    this.loading = false;
    if (!li.find('ul').length) {
      let html = [];
      if (nodes) {
        html.push('<ul>');
        $.each(nodes, function (index, node) {
          html.push('<li>');
          html.push('<label>');
          html.push('<i class="termView fa fa-caret-right" ' +
            'data-termId="' + node['id'] + '"></i>');
          html.push('<span class="viewSelector" data-key="' + node['id'] + '" data-value="' + node['name']
            + '" data-termType="' + node['termType'] + '" title="' + node['name'] + '"> ');
          html.push(node['name'] + ' </span>');
          html.push('</label>');
          html.push('</li>');
        });
        html.push('</ul>');

        let ele = html.join('');
        li.append(ele);
        label.addClass('active');
      }
    } else {
      li.find('ul').remove();
      label.removeClass('active');
    }

  }

  renderTreeNodes(result, element) {
    let li = $(element).closest('li');
    let label = $(element).closest('label');
    let nodes = result['termRelations'];
    this.loading = false;

    if (!li.find('ul').length) {
      let html = [];
      if (nodes) {
        html.push('<ul class="relations">');
        $.each(nodes, function (index, node) {
          html.push('<li>');
          html.push('<label>');
          html.push('<i class="relationView fa fa-caret-right" ' +
            'data-termId="' + result['id'] + '" data-rId="' + node['id'] + '" data-termType="' + result['termType'] + '"></i>');
          html.push('<span title="' + node['name'] + '"> ');
          html.push(node['name'] + ' </span>');
          html.push('</label>');
          html.push('</li>');
        });
        html.push('</ul>');

        let ele = html.join('');
        li.append(ele);
        label.addClass('active');

        //Random color icons
        li.find('.relationView').each(function () {
          $(this).css('color', ('#' + Math.floor(Math.random() * 16777215).toString(16)));
        });
      }
    } else {
      li.find('ul').remove();
      label.removeClass('active');
    }
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
  }

  viewSelector(key, value, termType) {
    let selected = {
      key: key,
      value: value,
      termType: termType
    };

    this.formSelected.emit(selected);
    let recentSearch = JSON.stringify(selected);
    if (selected['key']) {
      localStorage.setItem('recentTerm', recentSearch);
    }

    $('a.full-view-toggle').parent().removeClass('full-view');
  }

  ngOnChanges(changes) {
    if (changes.nodeKey && changes.nodeKey.previousValue !== changes.nodeKey.currentValue) {
      if (changes.nodeKey.currentValue) {
        this.nodeTree = this.nodeKey;

        $("ul.lex-tree-structure #root").find('ul').remove();
        this.treeRoot();
      }
    }
  }



}
