import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  projectId: string;
  header;

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getNotes(termId, start) {
    let body = {
      projectId: this.projectId,
      termId: termId,
      start: start
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/getScopeNote/', body, this.header);
  }

  saveScopeNote(termId, scope) {
    let data = {
      projectId: this.projectId,
      termId: termId,
      scopeSource: scope['source'],
      scopeSourceType: scope['type'],
      scopeSourceComment: scope['comment']
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/addScopeNote', data, this.header);
  }

  deleteScopeNote(termId, scope) {
    let data = {
      projectId: this.projectId,
      termId: termId,
      scopeSource: scope['source'],
      scopeSourceType: scope['sourceType'],
      scopeSourceComment: scope['sourceComment']
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/deleteScopeNote', data, this.header);
  }

  getSourceList() {
    return this.http.get(environment.app.SERVER__URL + 'cv/getSourceType/projectId/P1/cvName/cv');
  }

  getSourceType(source) {
    return this.http.get(environment.app.SERVER__URL + 'cv/getCvInfo/projectId/' + this.projectId + '/cvName/' + source);
  }
}
