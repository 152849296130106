import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {UserManagementService} from "./user-management.service";
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  userObj:any = {
    'readOnly' : false,
  };
  edit:boolean;
  loading:boolean;
  boolpreloader: boolean = false;

  users;

  deleteUser;
  modalRef: BsModalRef;
  @ViewChild('f' , {static: true}) form;

  constructor(private _userService: UserManagementService,
              private toastr: ToastrService,
              private modalService: BsModalService) { }

  ngOnInit() {
    localStorage.setItem('lastRoute', 'AddUser');
    this.getUsers();
  }

  getUsers() {
    this.boolpreloader = true;
    this._userService.getUsers().subscribe(
      result =>{
        this.boolpreloader = false;
        this.users = result;
      },
      err => {
        this.boolpreloader = false;
      }
    );

  }
  AddUser() {

    if (this.userObj.password === this.userObj.repassword) {

      if (this.edit) {
        this._userService.saveEditUser(this.userObj).subscribe(
          result => this.submitHandler(result),
          err => this.errorHandler(err)
        );
      } else {
        this._userService.addUser(this.userObj).subscribe(
          result => this.submitHandler(result),
          err => this.errorHandler(err)
        );
      }


    } else {
        this.toastr.error( 'Error! Passwords wont match!');
    }
    this.edit = false;
  }
  submitHandler(result) {

    this.getUsers();
    this.loading = false;
    this.form.resetForm();

    if (result['success']) {

        this.toastr.success('User added successfully!');

    } else {
        this.toastr.error('Error adding User!\n' + result.message);
    }

  }

  errorHandler(err) {
      this.toastr.error('Error adding user!');
    this.loading = false;
  }

  editUser(arr) {
    this.userObj.id = arr.id;
    this.userObj.username = arr.name;
    /*        this.userObj.password = arr.password;
     this.userObj.repassword = arr.password;*/
    this.userObj.email = arr.email;
    this.userObj.role = arr.role;
    this.userObj.type = arr.type;
    this.userObj.readOnly = arr.readOnly;

    this.edit = true;
    this.gotoTop();
  }
  openModal(template: TemplateRef<any>, user) {
    this.modalRef = this.modalService.show(template);
    this.deleteUser = user;
  }
  confirm(){
    this._userService.deleteUser(this.deleteUser.id).subscribe(
      result => {
        if (result['success']) {
            this.toastr.success('User deleted from project successfully!');

        } else {
            this.toastr.error('Error adding User!\n' + result['message']);
        }

        this.getUsers();
      },
      err => {
          this.toastr.error('Error removing user!');
        this.loading = false;
      })
    this.modalRef.hide();
  }



  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


}
