import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import {BlobService} from "./blob.service";
import $ from "jquery";
import {ToastrService} from "ngx-toastr";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-blob',
  templateUrl: './blob.component.html',
  styleUrls: ['./blob.component.scss']
})
export class BlobComponent implements OnInit {

  addNewBlobBool:boolean = false;
  editBlobBool: boolean = false;
  newBlobObj:any = {
    name: '',
    option: '',
    value: [],
    comment: ''
  };
  blobObjErr:any = {
    name: '',
    value: ''
  };
  blobId: '';
  blobValueOptions: any = [];
  error:any;
  loading:boolean;
  start:number = 0;
  pageNumber:number = 0;
  pages:number = 0;
  limit:number = 5;

  termToDelete:any;
  showConfirmation:boolean = false;
  confirmationMsg:string;
  deleteType:string;

  @Input() formSelected:any;
  @Input() blobData:any;
  @Output() change = new EventEmitter();

  modalRef:BsModalRef;

  constructor(private _blobService:BlobService,
              private modalService:BsModalService,
              private toastr:ToastrService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes['blobData'] && this.blobData) {
      this.getBlobInfo();
    }
  }

  ngAfterViewInit() {
  }

  addValue(e){
    e.preventDefault();
    if(!this.newBlobObj.option){
      return;
    }
    if(this.newBlobObj.value) {
      if(this.newBlobObj.value.indexOf(this.newBlobObj.option) === -1){
        this.newBlobObj.value.push(this.newBlobObj.option);
      }
      else{
        this.toastr.error("Duplicate entries not allowed!")
      }
    }
    else{ 
      this.newBlobObj.value = [];
      this.newBlobObj.value.push(this.newBlobObj.option);
    }
    this.newBlobObj.option = '';
  }

  deleteValue(getValue){
    this.newBlobObj.value = this.newBlobObj.value.filter(val => {
      return val !== getValue;
    })
  }

  getBlobInfo() {
    this.loading = true;
    let termId = this.formSelected['key'];
    this._blobService.getBlobInfo(termId, this.blobData, this.start).subscribe(
      result => this.updateInfo(result),
      err => this.errorHandler(err)
    );
  }

  saveNewBlob(blobData, evt) {
    if(!this.newBlobObj.name){
      this.blobObjErr.name = 'Name is required';
      return;
    }
    else{
      this.blobObjErr.name = '';
    }
    if(!this.newBlobObj.value){
      this.blobObjErr.value = 'Value is required';
      return;
    }
    else{
      if(this.newBlobObj.value.length){
        this.blobObjErr.value = '';
      } 
      else{
        this.blobObjErr.value = 'Value is required';
        return;
      }
    }
    this.loading = true;
    let termId = this.formSelected['key'];
    if(this.editBlobBool){
      blobData.id = this.blobId;
      this._blobService.editBlobInfo(termId, blobData, this.blobData['attribute']).subscribe(
        result => this.saveSuccess(result),
        err => this.errorHandler(err)
      );
    }
    else{
      this._blobService.saveBlobInfo(termId, blobData, this.blobData['attribute']).subscribe(
        result => this.saveSuccess(result),
        err => this.errorHandler(err)
      );
    }
  }

  editBlob(data){
    this.addNewBlobBool = !this.addNewBlobBool;
    this.editBlobBool = !this.editBlobBool;
    this.newBlobObj = data;
    this.blobId = data.id;
  }

  addNewBlob() {
    this.addNewBlobBool = !this.addNewBlobBool;
    this.newBlobObj = {};
  }

  updateInfo(result) {
    this.loading = false;
    this.blobData = result[0];
    this.pages = Math.ceil(result[0]['count'] / this.limit);
    this.pageNumber = (this.start !== 0) ? (this.start / this.limit) + 1 : 1;
  }


  nextPage() {
    if (this.pageNumber < this.pages) {
      this.start = this.start + this.limit;
      this.getBlobInfo();
    }
  }

  prevPage() {
    if (this.start > 0) {
      this.start = this.start - this.limit;
      this.getBlobInfo();
    }
  }

  saveSuccess(result) {
    if (result['success'] === true) {
      this.toastr["success"](result['message']);
    } else {
      this.toastr["error"](result['message']);
    }

    this.newBlobObj = {};
    this.addNewBlobBool = false;
    this.editBlobBool = false;
    this.start = 0;
    this.pageNumber = 0;
    this.pages = 0;
    this.getBlobInfo();
  }

  showConfirmationPopup(template:TemplateRef<any>, term) {
    if(Object.keys(term).length){
      this.termToDelete = term;
      this.deleteType = '';
    }
    else{
      this.deleteType = 'all';
    }
    this.confirmationMsg = 'Are you sure want to delete this note ?';
    this.showConfirmation = true;
    this.openModal(template);
  }

  openModal(template:TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  deleteBlobInfo(blobInfo) {
    let termId = this.formSelected['key'];
    if(this.deleteType === 'all'){
      this._blobService.deleteAllBlobInfo(termId, this.blobData['attribute']).subscribe(
        result => this.saveSuccess(result),
        err => this.errorHandler(err)
      );
    }
    else{
      this._blobService.deleteBlobInfo(termId, blobInfo, this.blobData['attribute']).subscribe(
        result => this.saveSuccess(result),
        err => this.errorHandler(err)
      );
    }
  }

  hideConfirmation(bool) {
    this.showConfirmation = false;
    this.modalRef.hide();
  }

  removeNote(approvalObj) {
    this.showConfirmation = false;
    if (approvalObj['isApproved']) {
      this.deleteBlobInfo(this.termToDelete);
    }
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
  }

  moreAndLessHandler(evt) {

    let target = $(evt.target);
    if (evt.target.innerText.trim() === "more >>") {
      evt.target.innerText = "less <<";
      target.closest('p').find('.sub-text').removeClass('hidden');
    } else {
      evt.target.innerText = "more >>";
      target.closest('p').find('.sub-text').addClass('hidden');
    }
  }

  moreAndLess(value) {

    if (typeof value === 'string' && value.length) {
      return value.split(' ').length > 20;
    }
  }

}
