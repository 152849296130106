import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserManagementService {

  projectId:string;
  header;

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }
  getUsers(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getUsers/' + this.projectId);
  }
  saveEditUser(user) {

    let param = 'pId=' + this.projectId + '&userId=' + user.id + '&password=' + user.password +
      '&role=' + user.role + '&type=' + user.type + '&email=' + user.email + '&readOnly=' + user.readOnly;

    return this.http.post(environment.app.SERVER__URL + 'Manager/editUserDetails', param, this.header);

  }
  addUser(user) {

    let param = 'pId=' + this.projectId + '&name=' + user.username + '&password=' + user.password +
      '&role=' + user.role + '&type=' + user.type + '&email=' + user.email + '&readOnly=' + user.readOnly;

    return this.http.post(environment.app.SERVER__URL + 'Manager/addUser', param, this.header);

  }
  deleteUser(id) {

    let param = this.projectId + "/"  + id;
    return this.http.get(environment.app.SERVER__URL + 'Manager/deleteUser/' + param);
  }
}
