import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;
import {NewFormService} from "./new-form.service";
import {ToastrService} from "ngx-toastr";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Common from '../../../util/common';

@Component({
  selector: 'app-new-form',
  templateUrl: './new-form.component.html',
  styleUrls: ['./new-form.component.scss']
})
export class NewFormComponent implements OnInit {

  error: any;
  loading: boolean;
  isTermNameEmpty: boolean = false;
  confirmToAddTerm: boolean = false;
  termName: any;

  formStruct: any = [];
  matchedTerms: any = [];

  @Input()
  newFormParam: any;

  @Output()
  newTerm = new EventEmitter<any>();
  modalRef:BsModalRef;

  constructor(private _newFormService: NewFormService,
              private toastr:ToastrService,
              private modalService:BsModalService) {
  }

  ngOnInit() {

  }

  getNewTermForm() {
    this.loading = true;
    let type = this.newFormParam['type']['id'];
    let projectId = this.newFormParam['projectId'];

    this._newFormService.newTermForm(type, projectId).subscribe(
      result => this.renderNewForm(result),
      err => this.errorHandler(err)
    );
  }

  renderNewForm(result) {
    let self = this;
    this.formStruct = result;
    this.loading = false;
    this.error = false;


    $(function () {

      $('.textEditor').each(function () {
        var $textArea = this;
        $($textArea).summernote({
          disableDragAndDrop: true,
          toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['insert', ['specialchars']]
          ]
        });

        Common.summernotePasteResetText($textArea);

        $($textArea).on('summernote.blur', function (we, contents, $editable) {
          let ele = we.currentTarget.id;

          if (ele) {
            var plainText = $($textArea).summernote("code");
            plainText = Common.removeNbsp(plainText);
            if (plainText === '') {
              self.isTermNameEmpty = false;
            } else {
              self.getMatchTerms(plainText, ele);
              //self.onBlurSave(plainText, ele);
            }
          }
        });
      });

    });
  }

  errorHandler(err) {
    //this.formStruct = null;

    this.error = err;
    this.loading = false;
    //let message = (err && err['message']) ? err['message'] : ' Something went wrong, Please try again.';
    //console.log(err);

    this.toastr["error"](err['error']['message']);
  }

  ngOnChanges(changes) {
    if (changes.newFormParam.previousValue !== changes.newFormParam.currentValue) {
      if (changes.newFormParam.currentValue) {
        $('#newForm')[0].reset();
        this.getNewTermForm();
        this.error = '';
        this.loading = false;
      }
    }
  }

  onBlurSave(value, element) {

    let val = value.trim();
    let text = Common.removeTags(val);
    if (text) {
      let newTerm = {
        projectId: this.newFormParam['projectId'],
        [element]: value,
        termType: this.newFormParam['type']['id']
      };

      this.loading = true;
      this._newFormService.addNewTerm(newTerm).subscribe(
        result => this.addTermSuccess(result),
        err => this.errorHandler(err)
      );
    } else {
      this.toastr['warning']("Please enter term name");
    }
  }

  getMatchTerms(value, element) {
    this.loading = true;
    this._newFormService.getMatchedTerms(value).subscribe(
      result => this.showMatchedTerms(result, element, value),
      err => this.errorHandler(err)
    );
  }

  showMatchedTerms(res, element, term) {
    this.loading = false;
    if (res['result'].length) {
      this.matchedTerms = res['result'];
      this.confirmToAddTerm = true;
      this.termName = {termName: term, ele: element};
    } else {
      this.onBlurSave(term, element);
    }
  }

 approvedToAddTerm(termObj) {

    this.onBlurSave(termObj['termName'], termObj['ele']);
  }

  addTermSuccess(result) {
    this.loading = false;
    if (result['cid'] && result['name']) {
      this.toastr["success"](result['message']);
      let selected = {
        key: result['cid'],
        value: Common.removeTags(result['name']),
        termType: result['termType']
      };

      this.newTerm.emit(selected);
    } else {
     this.toastr["error"](result['message']);
    }
  }

}
