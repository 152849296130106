import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {QuickAddTermService} from "./quick-add-term.service";
import {NewFormService} from "../new-form/new-form.service";
declare var $:any;
import Common from '../../../util/common';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-quick-add-term',
  templateUrl: './quick-add-term.component.html',
  styleUrls: ['./quick-add-term.component.scss']
})
export class QuickAddTermComponent implements OnInit {

  error:any;
  loading:boolean;
  modalTitle:string = 'Add new term';
  formStruct: any = [];
  formType: any;
  arrTerm: any = {
    name: ''
  };

  confirmToAddTerm: boolean = false;
  termName: any;
  actionEvt: any;
  matchedTerms: any = [];

  chooseTermType:string = '';
  @Input() showModal:boolean;
  @Input() quickAddTerm: any;
  @Input() termTypesArr: any;
  @Output() change = new EventEmitter();
  @Output() newQuickTermAdded = new EventEmitter();

   myDatePickerOptions = {
    dateFormat: 'dd-mm-yyyy',
    editableDateField: false
  };

  constructor(private _quickAddTermService:QuickAddTermService,
              private toastr:ToastrService,
              private _newFormService: NewFormService) {
  }

  ngOnInit() {
    // this.getNewTermForm();

    this.arrTerm['name'] = this.quickAddTerm['newtermAdd'];
  }

  ngAfterViewInit() {

  }

  setTermType(event) {
    if (event.target.value !== '') {
      this.formType = {
        termType: event.target.value,
        name: event.target.options[event.target.selectedIndex].text
      };

      this.quickAddTerm['termType'] = event.target.value;
      this.getNewTermForm();
    } else {
      this.formType = {};
      this.formStruct = [];
      this.chooseTermType = '';
      this.modalTitle = 'Add new term';
    }
  }

  getNewTermForm() {
    this.loading = true;
    let type = this.quickAddTerm['termType'];
    let projectId = localStorage.getItem('projectId');

    this._quickAddTermService.newTermForm(type, projectId).subscribe(
      result => this.renderNewForm(result),
      err => this.errorHandler(err)
    );
  }

  renderNewForm(result) {
    this.formStruct = result;
    this.loading = false;
    this.error = false;
    // this.formType = this.termTypesArr.find(myObj => myObj['id'] === this.quickAddTerm['termType']);

    this.modalTitle = "Add new " + this.formType['name'];


    let con = this;
    $(function () {

      $("input[data-role=tagsinput], select[multiple][data-role=tagsinput]").tagsinput({
        delimiter: ';',
        confirmKeys: [13]
      });

      $('.input-tags').on('itemAdded', function (event) {
        var ele = event.target.dataset.attrname;

        if (ele) {
          let newValue = Common.addInputTag(event, '.newForm', ele);
          con['arrTerm'][ele] = newValue;
        }
      });

      $('.input-tags').on('itemRemoved', function (event) {
        var ele = event.target.dataset.attrname;
        if (ele) {
          let newValue = Common.removeInputTag(event, '.newForm', ele);
          con['arrTerm'][ele] = newValue;
        }
      });

      $('.textEditor').each(function () {
        var $textArea = this;
        $($textArea).summernote({
          disableDragAndDrop: true,
          toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['insert', ['specialchars']]
          ]
        });

        Common.summernotePasteResetText($textArea);

        //summernote.focus
        $($textArea).on('summernote.change', function (we, contents, $editable) {
          let ele = we.currentTarget.id;
          if (ele) {
            let value = Common.removeNbsp(contents);
            con['arrTerm'][ele] = value;
          }
        });
      });
    });

  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;

    if (err['message']) {
      this.toastr['error']('Error', err['message']);
    }
  }

  modalChange(event) {
    this.showModal = event;
    this.change.emit(this.showModal);
  }

  getMatchTerms(evt, value) {
    this.loading = true;
    this._newFormService.getMatchedTerms(value['name']).subscribe(
      result => this.showMatchedTerms(evt, result, value),
      err => this.errorHandler(err)
    );

    //console.log();
  }

  showMatchedTerms(evt, res, term) {
    this.loading = false;
    if (res['result'].length) {
      this.matchedTerms = res['result'];
      this.confirmToAddTerm = true;
      this.termName = {termName: term};
      this.actionEvt = evt ;
    } else {
      this.saveNewTerm(term, evt);
    }
  }

  public approvedToAddTerm(termObj) {

    this.saveNewTerm(termObj['termName'], this.actionEvt);
  }

  saveNewTerm(form, event:Event) {
    event.preventDefault();

    let formData = {};
    Object.keys(form).forEach(function (key) {
      if (!key.match(/[a-zA-Z]*_join$/g)) {
        formData[key] = form[key];
      }
    });

    form = formData;

    let projectId = localStorage.getItem('projectId');
    form['termType'] = this.quickAddTerm['termType'];
    let text = Common.removeTags(form['name']);

    if (!text) {
      this.toastr['warning']('Warning', 'Please enter term name');
    } else if (projectId && form['termType'] && text) {
      this._quickAddTermService.addNewTerm(projectId, form).subscribe(
        result => this.saveNewTermResult(result),
        err => this.errorHandler(err)
      );
    } else {
      this.toastr['error']('Error', 'Something went wrong!');
    }
  }

  saveNewTermResult(result) {
    if (result['success'] === true) {
      this.toastr['success']('Success', result['message']);
      $('#commonModal').modal('hide');
      this.modalChange(false);
      this.newQuickTermAdded.emit(true);
    } else {
      this.toastr['error']('Error', result['message']);
    }
  }


  onDateChanged(event, data_field) {
    this.arrTerm[data_field] = event['formatted'];
  }

}
