import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
declare var $: any;
import {ToastrService} from "ngx-toastr";
import {Router, ActivatedRoute} from '@angular/router';
import {RelationsService} from "./relations.service";
import { environment } from 'src/environments/environment';
import Common from '../../../util/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.scss']
})
export class RelationsComponent implements OnInit {

  error:any;
  loading:boolean;
  deleteLoading:boolean;
  preRecentHistory:boolean;
  termsLoading:boolean;
  relation:string; // excepted relation
  relations: any = [];
  terms: any = [];
  relationView: any = [];
  relationViewPrev: any = [];
  relId:number;
  searchTerm:string;
  searchKeyword:string;
  hasSearchTerm:string;
  confirmationMsg:string;
  deleteType:string;
  showConfirmation:boolean = false;
  showServError:boolean = false;
  termToDelete:any;

  addNewRelationFlag:boolean = false;
  newRelation:any = {
    term: '',
    comment: ''
  };
  termSearchMade:boolean = false;
  termFound:boolean = false;
  sourceList: any = [];
  sourceTypeArr: any= [];

  expectedRelations:any = [];
  recentTerms:any = [];
  chooseTermType:any = [];

  //paginate
  totalCount:number;
  offset:number = 0;
  limit:number = 10;
  pages:number = 1;
  pageNumber:number;

  //autosuggest
  currentFocus:number = 0;
  suggestedValues: any = [];
  showDropDown: boolean = false;
  timer: any = 0;
  delay: number = 500;
  preLoader: boolean = false;
  searchItem: string = '';

  @Input()
  formSelected: any;

  @Input()
  relationValues: any = [];

  @Input()
  transitiveRelations: any = [];

  @Input()
  termColorArr: any = [];


  @Output()
  setNewTermView = new EventEmitter< any>();

  @Output()
  quickAddModalObj = new EventEmitter<any>();
  modalRef:BsModalRef;

  constructor(private router:Router,
              private toastr:ToastrService,
              private modalService:BsModalService,
              private _relationsService: RelationsService) {

  }

  ngOnInit() {


  }

  getRelations() {
    this.loading = true;
    let termId = this.formSelected['key'];
    this._relationsService.getRelations(termId).subscribe(
      result => this.setRelations(result),
      err => this.errorHandler(err)
    );
  }

  setRelations(relations) {
    this.loading = false;
    this.relations = relations;

    if (this.relations) {

      if (this.relationViewPrev.length) {
        this.viewRelation(this.relationViewPrev);
      } else {
        this.viewRelation(this.relations[0]);
      }

    }
    this.error = null;
  }

  viewRelation(rid) {
    if (rid) {
      this.relationView = rid;
      this.relationViewPrev = rid;
      this.relId = rid['id'];

      this.searchTerm = '';
      this.searchKeyword = '';
      this.terms = [];
      this.offset = 0;
      this.pageNumber = 1;
      this.newRelation = {};
      this.termSearchMade = false;

      this.totalCount = rid['count'];
      this.pageNumber = (this.offset !== 0) ? (this.offset / this.limit) + 1 : 1;
      this.pages = (this.totalCount > this.limit) ? (Math.ceil(this.totalCount / this.limit)) : 1;

      this.getTermSearchResult();
    }
  }

  getTermSearchResult() {
    this.termsLoading = true;
    let termId = this.formSelected['key'];
    let termType = this.formSelected['termType'];
    this.hasSearchTerm = (this.searchTerm !== '') ? this.searchTerm : 'empty';


    if (this.relationValues && this.relationValues[termType]['relations'][this.relId] !== '') {
      let relationValue = this.relationValues[termType]['relations'][this.relId];
      this._relationsService.getTerms(termId, this.relId, termType, this.hasSearchTerm, this.offset, relationValue).subscribe(
        result => this.setTermsList(result),
        err => this.errorHandler(err)
      );
    }
  }

  setTermsList(terms) {
    this.termFound = false;
    this.totalCount = terms['count'];
    this.terms = terms['records'];

    for (let i = 0; i < this.terms.length; i++) {
      if (this.hasSearchTerm === this.terms[i]['name']) {
        this.termFound = true;
      }
    }

    this.pages = (this.totalCount > this.limit) ? (Math.ceil(this.totalCount / this.limit)) : 1;

    this.termsLoading = false;
    this.error = null;
  }


  searchTermFun() {
    this.hasSearchTerm = '';
    this.terms = [];
    this.offset = 0;
    this.pageNumber = 1;
    this.searchKeyword = this.searchTerm;
    this.termSearchMade = true;
    this.getTermSearchResult();
  }


  resetTermSearch() {
    this.searchTerm = '';
    this.searchTermFun();
    this.termSearchMade = false;
  }

  nextPage() {
    if (this.pageNumber < this.pages) {
      this.offset = this.offset + this.limit;
      this.loadMoreTerms();
    }
  }

  prevPage() {
    if (this.offset > 0) {
      this.offset = this.offset - this.limit;
      this.loadMoreTerms();
    }
  }

  loadMoreTerms() {
    this.pageNumber = (this.offset !== 0) ? (this.offset / this.limit) + 1 : 1;
    this.getTermSearchResult();
  }


  //Select term from relation panel
  autoSuggestSelection(key, value, termType) {
    if (key !== '' && value !== '' && termType !== '') {
      this.setNewTermView.emit({key, value, termType});
    }
  }

  uniq(a, param) {
    return a.filter(function (item, pos, array) {
      return array.map(function (mapItem) {
          return mapItem[param];
        }).indexOf(item[param]) === pos;
    });
  }

  //Add new relation
  addNewRelation(flag) {
    this.addNewRelationFlag = (flag) ? true : false;

    if (this.addNewRelationFlag) {
      let rid = this.relationView['id'];
      let termType = this.formSelected['termType'];

      let x = this.relationValues[termType]['transitive'][rid] || [];


      this.chooseTermType = this.uniq(x, 'id');
      this.chooseTermType = this.chooseTermType.filter(function (obj) {
        return obj['id'].charAt(0) !== '\n';
      });

    }

    if (flag) {
      this.newRelation = {};
      this.getSourceList();
      this.getRecentHistory();
      this.newRelation['relTermType'] = this.chooseTermType[0]['id'] || '';
    } else {
      this.viewRelation(this.relationViewPrev);
    }

  }
  hide() {
    this.showDropDown = false;
    this.currentFocus = 0;
  }
  //
  show() {
    this.showDropDown = true;
  }

  termAutosuggest(searchPanel, event) {

    this.searchItem = searchPanel;
    let com = this;

    com.newRelation['termId'] = '';
    com.newRelation['termType'] = '';

    if (event.keyCode == 40){
      this.currentFocus++ ;
      if (this.currentFocus >this.suggestedValues.length){
        this.currentFocus = 0;
      }
      return;
    } else if (event.keyCode == 38) { //up
      if(this.currentFocus == 0){
        return
      }
      /*If the arrow UP key is pressed,
       decrease the currentFocus variable:*/
      this.currentFocus--;
      return;
    } else if (event.keyCode !== 13) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.preLoader = true;
        if (searchPanel.length > 0) {
          this.suggestedValues = [];
          let objData = [
            {
              "key": "term",
              "value": searchPanel,
              "dataType": "String"
            },
            {
              "key": "termType",
              "value": (this.relationView['termType'] === 'ALL') ? '' : this.relationView['termType']
            },
            {
              "key": "approved",
              "value": 1
            }
          ];
          this._relationsService.getAutosuggest(objData).subscribe(res=>{
            this.suggestedValues = res;
            this.preLoader = false;
          }, err=>{
            this.preLoader = false;
            this.toastr.error('Something went wrong!')
          });

          this.show();
        } else {
          // this.keyUpValue.emit('');
        }
      }, this.delay);


    } else if(event.keyCode == 13){
      if(this.currentFocus >= 1){
        // this.keyword = this.suggestedValues[this.currentFocus-1];
        // this.keyChoosenValue.emit(this.keyword);
        // this.keyword='';
      }
      this.hide();
    } else {
      this.hide();
    }


    //let element = $("#addTermRelation");
    //let projectId = localStorage.getItem('projectId');
    ////let url = Config.SERVER__URL + 'getSuggestTerm/projectId/' + projectId;
    //let query = element.val();
    ////let param = JSON.stringify({
    ////     approved: 1,
    ////     term: searchPanel,
    ////     termType: this.relationView['termType']
    //// });


    //let url = environment.app.SERVER__URL + 'getSuggestTerm/search/projectId/' + projectId;
    //
    //
    //
    //let param = JSON.stringify(objData);
    ////let param = objData;
    //
    //
    //let token = JSON.parse(localStorage.getItem('token'));
    //
    //let timer;
    //
    //if (timer) {
    //  clearTimeout(timer);
    //}
    //timer = setTimeout(() => {
    //  if (query && query.length) {
    //    let list = [];
    //    if (element.siblings('span.auto-complete-loader').length === 0) {
    //      $("<span class='auto-complete-loader'>Please wait...</span>").insertAfter(element);
    //    }
    //    $.ajax({
    //      url: url,
    //      type: 'POST',
    //      contentType: 'application/x-www-form-urlencoded; charset=utf-8',
    //      data: param,
    //      beforeSend: function (xhr) {
    //        xhr.setRequestHeader('token', token);
    //      },
    //      success: function (result) {
    //        $('.auto-complete-loader').remove();
    //        if (Array.isArray(result)) {
    //          $.each(result, function (index, value) {
    //            list.push('<li>');
    //            list.push('<a href="javascript:void(0);" data-key="' + value['key'] +
    //              '" data-value="' + value['value'] + '" data-termType="' + value['termType'] + '">' +
    //              Common.highlight(value['value'], query) + '</a>');
    //            list.push('</li>');
    //          });
    //        }
    //
    //        if (element.siblings('ul.auto-dropdown-options').length === 0) {
    //          $("<ul class='auto-dropdown-options pl-1'></ul>").insertAfter(element);
    //        }
    //        if (element.siblings('ul.auto-dropdown-options').length) {
    //          element.siblings('ul.auto-dropdown-options').html('<li>' + list.join('') + '</li>');
    //        }
    //
    //        $("ul.auto-dropdown-options li").on("click", "a", function (event) {
    //          event.preventDefault();
    //
    //          let key = $(this).attr("data-key");
    //          let value = $(this).attr("data-value");
    //          let termType = $(this).attr("data-termType");
    //
    //
    //          if (com.formSelected['key'] === key) {
    //            com.toastr['warning']('Invalid term', 'Term cannot be same as parent term name');
    //            com.newRelation['term'] = '';
    //          } else {
    //            com.newRelation['termId'] = key;
    //            com.newRelation['term'] = value;
    //            com.newRelation['termType'] = termType;
    //            com.newRelation['toTermType'] = com.relationView['termType'];
    //          }
    //
    //
    //        });
    //      },
    //      error: function (error) {
    //        if (error['status'] === 500) {
    //          com.showServError = true;
    //          $('.auto-complete-loader').html("Error: Something went wrong!");
    //        } else {
    //          $('.auto-complete-loader').html("Error: Something went wrong!");
    //        }
    //      }
    //    });
    //
    //
    //  } else {
    //    element.siblings('ul.auto-dropdown-options').remove();
    //  }
    //}, 400);
  }
  onSelectedValue(list){
    event.preventDefault();

    let key = list.key;
    let value = list.value;
    let termType = list.termType;


    if (this.formSelected['key'] === key) {
      this.toastr['warning']('Invalid term', 'Term cannot be same as parent term name');
      this.newRelation['term'] = '';
      this.hide();
    } else {
      this.newRelation['termId'] = key;
      this.newRelation['term'] = value;
      this.newRelation['termType'] = termType;
      this.newRelation['toTermType'] = this.relationView['termType'];
      this.hide();
    }
  }

  getSourceList() {
    this._relationsService.getSourceList().subscribe(
      result => this.sourceList = result,
      err => this.errorHandler(err)
    );
  }

  getSourceType(source) {
    if (source === 'default') {
      $('#sourceType').html('');
    } else {
      this._relationsService.getSourceType(source).subscribe(
        result => this.sourceTypeAppend(result),
        err => this.errorHandler(err)
      );
    }

  }

  sourceTypeAppend(result) {

    this.sourceTypeArr = result;
    this.newRelation['sourceType'] = '';

    $('#sourceType').html('');
    $('#sourceType').append($('<option>', {
      value: '',
      text: '--Choose--'
    }));

    for (let i = 0; i < result.length; i++) {
      $('#sourceType').append($('<option>', {
        value: result[i]['value'],
        text: result[i]['value']
      }));
    }

  }


  saveNewRelation(event, form) {
    event.preventDefault();

    if (this.newRelation['termId'] !== '') {
      let data = {
        projectId: localStorage.getItem('projectId'),
        fromTermId: this.formSelected['key'],
        termType: this.formSelected['termType'],
        toTermId: this.newRelation['termId'],
        toTermType: this.newRelation['toTermType'],
        source: this.newRelation['source'],
        sourceType: this.newRelation['sourceType'],
        comment: this.newRelation['comment'],
        relationId: this.relationView['id']
      };

      let termType = this.formSelected['termType'];
      if (this.relationValues && this.relationValues[termType] && this.relationValues[termType]['relations'][this.relId] !== '') {
        data['relationValue'] = this.relationValues[termType]['relations'][this.relId];
        data['transitiveId'] = this.newRelation['relTermType'];
        data['transitiveValue'] = this.relationValues[termType]['relations'][data['transitiveId']];

        this._relationsService.addNewRelation(data).subscribe(
          result => this.relationAddedSuccess(result, form),
          err => this.errorHandler(err)
        );
      }

    } else {
      this.toastr['warning']('Invalid term, Please choose from the list');
    }
  }

  relationAddedSuccess(result, form) {
    if (result['success'] === true) {
      this.toastr['success'](result['message']);
      $('#sourceType').html('');

      this.newRelation = {};
      this.newRelation['relTermType'] = this.chooseTermType[0]['id'] || '';

      let key = this.formSelected['key'];
      let value = this.formSelected['value'];
      let termType = this.formSelected['termType'];
      this.autoSuggestSelection(key, value, termType);

      this.relationView['count'] = this.relationView['count'] + 1;

      this.totalCount = this.relationView['count'];
      this.pages = (this.totalCount > this.limit) ? (Math.ceil(this.totalCount / this.limit)) : 1;
      form.resetForm();
      this.getRecentHistory();
    } else {
      this.toastr['error'](result['message']);
    }
  }


  deleteRelation(term) {


    //let flag = confirm("Are you sure want to delete this relation ?");
    //if (flag) {
    let termType = this.formSelected['termType'];
    let rid = this.relationView['id'];
    let data = {
      projectId: localStorage.getItem('projectId'),
      fromTermId: this.formSelected['key'],
      termType: termType,
      relationId: this.relationView['id'],
      toTermId: term['id'],
      relationValue: this.relationValues[termType]['relations'][rid]
    };
    this.deleteLoading = true;
    this._relationsService.deleteRelation(data).subscribe(
      result => this.relationDeleteSuccess(result),
      err => this.errorHandler(err)
    );
    //}
  }

  relationDeleteSuccess(result) {
    if (result['success'] === true) {
      this.toastr['success'](result['message']);
      this.deleteLoading = false;

      this.viewRelation(this.relationViewPrev);

      let key = this.formSelected['key'];
      let value = this.formSelected['value'];
      let termType = this.formSelected['termType'];
      this.autoSuggestSelection(key, value, termType);

      this.relationView['count'] = this.relationView['count'] - 1;

      this.totalCount = this.relationView['count'];
      this.pages = (this.totalCount > this.limit) ? (Math.ceil(this.totalCount / this.limit)) : 1;
    } else {
      this.toastr['error'](result['message']);
    }
  }


  quickTermAdd(relationView) {
    relationView['newtermAdd'] = (this.searchTerm) ? this.searchTerm : '';
    this.quickAddModalObj.emit(relationView);

    this.resetTermSearch();
  }

  showConfirmationPopup(template: TemplateRef<any>, type, term = {}) {

    this.termToDelete = term;
    let deleteAllRelMsg = 'Are you sure want to delete all the relations from this term ?';
    let deleteRelMsg = 'Are you sure want to delete this relation ?';
    this.deleteType = type === 'all' ? 'all' : 'single';
    this.confirmationMsg = type === 'all' ? deleteAllRelMsg : deleteRelMsg;
    this.showConfirmation = true;
    this.openModal(template);
  }
  openModal(template:TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  deleteAllRelation() {


    //let flag = confirm("Are you sure want to delete all the relations from this term ?");
    //
    //if (flag) {
    let termId = this.formSelected['key'];
    let termType = this.formSelected['termType'];
    let projectId = localStorage.getItem('projectId');
    let rid = this.relationView['id'];

    let data = {
      projectId: projectId,
      relationId: rid,
      relationValue: this.relationValues[termType]['relations'][rid],
      fromTermId: termId,
      termType: termType
    };
    this.deleteLoading = true;
    this._relationsService.deleteAllRelations(data).subscribe(
      result => this.deleteAllRelResult(result),
      err => this.errorHandler(err)
    );

    //}
  }

  public hideConfirmation(bool) {
    this.showConfirmation = false;
    this.modalRef.hide();
  }

  public deleteRelations(approvalObj) {
    this.showConfirmation = false;
    if (approvalObj['isApproved']) {

      approvalObj['option'] === 'all' ? this.deleteAllRelation() : this.deleteRelation(this.termToDelete);
    }
  }

  deleteAllRelResult(result) {
    if (result['success'] === true) {
      this.toastr['success'](result['message']);

      this.deleteLoading = false;

      this.relationView['count'] = 0;
      this.relationViewPrev['count'] = 0;

      this.viewRelation(this.relationViewPrev);

      this.addNewRelationFlag = false;

      let key = this.formSelected['key'];
      let value = this.formSelected['value'];
      let termType = this.formSelected['termType'];
      this.autoSuggestSelection(key, value, termType);
    } else {
      this.relation = '';
      this.toastr['error'](result['message']);
    }
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
    this.deleteLoading = false;
    this.termsLoading = false;
    this.preRecentHistory = false;
    this.toastr['error'](err['message']);
  }


  cardClick(rel) {
    this.addNewRelationFlag = false;
    this.viewRelation(rel);
  }


  getRecentHistory() {
    this.preRecentHistory = true;
    this._relationsService.getRecentHistory(this.relationView, this.formSelected).subscribe(
      result => this.getRecentHistoryResult(result),
      err => this.errorHandler(err)
    );
  }

  getRecentHistoryResult(result) {
    this.recentTerms = result;
    this.preRecentHistory = false;
  }

  ngOnChanges(changes) {
    let oldArr = (changes.formSelected.previousValue) ? changes.formSelected.previousValue : [];
    let newArr = (changes.formSelected.currentValue) ? changes.formSelected.currentValue : [];
    if (oldArr['key'] !== newArr['key'] || oldArr['value'] !== newArr['value'] || oldArr['termType'] !== newArr['termType']) {
      this.terms = [];
      this.relationView = [];
      this.relationViewPrev = [];
      this.newRelation = {};
      this.relations = [];
      this.addNewRelationFlag = false;
      this.getRelations();
      this.termSearchMade = false;
    }

  }

}
