import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//interceptor
import {HttpInterceptorService} from "./util/http/http-interceptor.service";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//service
import {AuthService} from "./auth/auth.service";

//Module
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import {MyDatePickerModule} from "mydatepicker/index";

//component
import {AuthComponent} from "./auth/auth.component";
import {LoginComponent} from "./auth/login/login.component";
import {ForgetComponent} from "./auth/forget/forget.component";
import {LogoutComponent} from "./auth/logout/logout.component";
import {LoginPopupComponent} from "./auth/login-popup/login-popup.component";
import { RolefilterPipe } from './util/pipes/rolefilter.pipe';
import { FooterComponent } from './common/footer/footer.component';
import { AdminComponent } from './admin/admin.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import {PreloaderComponent} from "./common/preloader/preloader.component";
import { AddManagerComponent } from './admin/add-manager/add-manager.component';
import { ManagerFilterPipe } from './util/pipes/manager-filter.pipe';
import { MustMatchDirective } from './util/directives/must-match.directive';
import { ProjectListComponent } from './common/project-list/project-list.component';
import { ManagerComponent } from './manager/manager.component';
import { ManagerDashboardComponent } from './manager/manager-dashboard/manager-dashboard.component';
import { RelationPipe } from './util/pipes/relation.pipe';
import { ConfirmPopupComponent } from './common/confirm-popup/confirm-popup.component';
import { UserManagementComponent } from './manager/user-management/user-management.component';
import { CvManagementComponent } from './manager/cv-management/cv-management.component';
import { CuratorComponent } from './curator/curator.component';
import { CuratorDashboardComponent } from './curator/curator-dashboard/curator-dashboard.component';
import { VisualizeComponent } from './curator/visualize/visualize.component';
import { ExportComponent } from './curator/export/export.component';
import { ImportComponent } from './curator/import/import.component';
import { DownloadComponent } from './curator/download/download.component';
import { TruncatePipe } from './util/pipes/truncate.pipe';
import { HighlightPipe } from './util/pipes/highlight.pipe';
import { SearchResultComponent } from './curator/curator-dashboard/search-result/search-result.component';
import { TreeComponent } from './curator/curator-dashboard/tree/tree.component';
import { HistoryComponent } from './curator/curator-dashboard/history/history.component';
import { NewFormComponent } from './curator/curator-dashboard/new-form/new-form.component';
import { TermMatchComponent } from './common/term-match/term-match.component';
import { EditFormComponent } from './curator/curator-dashboard/edit-form/edit-form.component';
import { CheckLabelPipe } from './util/pipes/check-label.pipe';
import { SaveAsNewComponent } from './curator/curator-dashboard/save-as-new/save-as-new.component';
import { NoteComponent } from './curator/curator-dashboard/note/note.component';
import { BlobComponent } from './curator/curator-dashboard/blob/blob.component';
import { RelationsComponent } from './curator/curator-dashboard/relations/relations.component';
import { MorelessPipe } from './util/pipes/moreless.pipe';
import { AnchorPipe } from './util/pipes/anchor.pipe';
import { VisualizeSearchComponent } from './curator/visualize/visualize-search/visualize-search.component';
import { GalaxyChartComponent } from './curator/visualize/galaxy-chart/galaxy-chart.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ProfileComponent } from './curator/profile/profile.component';
import { QuickAddTermComponent } from './curator/curator-dashboard/quick-add-term/quick-add-term.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    ForgetComponent,
    LogoutComponent,
    PreloaderComponent,
    LoginPopupComponent,
    RolefilterPipe,
    FooterComponent,
    AdminComponent,
    AdminDashboardComponent,
    AddManagerComponent,
    ManagerFilterPipe,
    MustMatchDirective,
    ProjectListComponent,
    ManagerComponent,
    ManagerDashboardComponent,
    RelationPipe,
    ConfirmPopupComponent,
    UserManagementComponent,
    CvManagementComponent,
    CuratorComponent,
    CuratorDashboardComponent,
    VisualizeComponent,
    ExportComponent,
    ImportComponent,
    DownloadComponent,
    TruncatePipe,
    HighlightPipe,
    SearchResultComponent,
    TreeComponent,
    HistoryComponent,
    NewFormComponent,
    TermMatchComponent,
    EditFormComponent,
    CheckLabelPipe,
    SaveAsNewComponent,
    NoteComponent,
    BlobComponent,
    RelationsComponent,
    MorelessPipe,
    AnchorPipe,
    VisualizeSearchComponent,
    GalaxyChartComponent,
    ResetPasswordComponent,
    ProfileComponent,
    QuickAddTermComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    MyDatePickerModule,
    NgSelectModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
