import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {mapTo, tap} from 'rxjs/internal/operators';
import {BehaviorSubject} from 'rxjs';

interface CacheFailed {
  request: any;
  error: any;
  handler: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loaderSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private popupSubject = new BehaviorSubject<any>({});
  popup = this.popupSubject.asObservable();

  public _preLoader: boolean;
  public _user;
  public _token;
  public _accessToken;
  public _refreshToken;


  setPopupSubject(val) {
    this.popupSubject.next(val)
  }

  getPopupSubject() {
    return this.popupSubject;
  }

  get preLoader() {
    return this._preLoader;
  }

  set preLoader(value) {
    if (value) {
      this.loaderSubject.next(this.loaderSubject.value + 1);
    } else {
      this.loaderSubject.next(this.loaderSubject.value - 1);
    }
    this._preLoader = value;
  }

  constructor(public http: HttpClient) {
    this.doLoginUser = this.doLoginUser.bind(this);
  }

  get user() {
    this._user = JSON.parse(localStorage.getItem('userInfo'));
    return this._user;
  }

  set user(value) {
    localStorage.setItem('userInfo', JSON.stringify(value));
    this._user = value;
  }

  get token() {
    this._user = JSON.parse(localStorage.getItem('token'));
    return this._user;
  }

  set token(value) {
    localStorage.setItem('token', JSON.stringify(value));
    this._user = value;
  }

  get accessToken() {
    this._accessToken = localStorage.getItem('access_token');
    return this._accessToken;
  }

  set accessToken(value) {
    localStorage.setItem('access_token', value);
    this._accessToken = value;
  }

  get refreshToken() {
    this._refreshToken = localStorage.getItem('refresh_token');
    return this._refreshToken;
  }

  set refreshToken(value) {
    localStorage.setItem('refresh_token', value);
    this._refreshToken = value;
  }


  addCredientials() {
    //return new HttpHeaders()
    //  .set('Authorization', 'Basic ' +
    //    btoa(environment.app.baseAuth.username + ':' + environment.app.baseAuth.password));
  }

  // addCredientials() {
  //   return new HttpHeaders()
  //     .set('Content-Type', 'multipart/form-data');
  // }


  onLogin(objData) {
    if (objData.username && objData.password) {

      let options = {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      };
      const fd = new URLSearchParams();
      fd.append('name', objData.username);
      fd.append('password', objData.password);
      fd.append('role', objData.role);

      return this.http.post(environment.app.SERVER__URL + 'user/authenticate',fd.toString(), options)
        .pipe(
          tap(tokens => this.doLoginUser(tokens))

        );

    }
  }

  onRefreshToken() {

    // Refresh token login on 400 error

    const headers = this.addCredientials();
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    if (this.accessToken && this.refreshToken) {
      const fd = new URLSearchParams();
      fd.append('refreshToken', this.refreshToken);
      fd.append('accessToken', this.accessToken);
      fd.append('grantType', 'refresh_token');

      return this.http.post(environment.app.SERVER__URL + 'api/login/getAccessToken', fd.toString(), options)
        .pipe(
          tap(tokens => this.doLoginUser(tokens))
        );
    }
  }

  onLogout() {

    const options = {
      headers: new HttpHeaders().set('accessToken', this.accessToken)
    };

    return this.http.delete(environment.app.SERVER__URL + 'api/login/invalidateToken', options )
      .pipe(
        tap(res => this.doLogoutUser(res))
      );
  }

  doLoginUser(tokens) {

    // Setting values in localStorage
    this.user = tokens['userInfo'];
    if(tokens['token']){
      this.token = tokens['token'];
    }
  }
  initiateResetPassword(email) {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    if (email) {
      let data = "emailId=" + email;
      return this.http.post(environment.app.SERVER__URL + 'user/resetPassword', data, options );
    }
  }
  updatePassword(value) {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    if (value['password'] && value['authToken']) {
      let data = "password=" + value['password'] + "&authToken=" + value['authToken'];
      return this.http.post(environment.app.SERVER__URL + 'user/updatePassword', data, options );
    }

  }

  doLogoutUser(user) {
    this.user = null;
    this.accessToken = null;
    this.refreshToken = null;
  }
}
