import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(text:string, search:string[]):string {
    let pattern = (search[0]) ? search[0] : '';
    pattern = this.escapeRegExp(pattern);

    let searchRgx = new RegExp(pattern, 'gi');
    return pattern ? text.replace(searchRgx, function (search) {
      return '<span class="highlight">' + search + '</span>';
    }) : text;

  }


  escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

}
