import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relation'
})
export class RelationPipe implements PipeTransform {

  transform(value:Array<any>, args:any[] = null):any {

    let projects = [];
    for (var i = 0; i < value.length; i++) {
      let obj = value[i];



      if (obj) {
        let name = obj['name'];
        let id = obj['id'];


        projects.push(name);


      }
    }


    return projects.join(', ');
  }

}
