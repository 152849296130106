import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import $ from "jquery";
import {CuratorDashboardService} from "../curator-dashboard.service";

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {

  error:any;
  loading:boolean;
  filterTypesloading:boolean;
  options: any = [];
  types: any;
  param:any;
  filterType:string = '';

  //paginate
  totalCount:number;
  offset:number = 0;
  limit:number = 50;
  pages:number = 1;
  pageNumber:number = 0;

  @Input() searchSubmitted: string;
  @Input() termSelected: string;
  @Input() sortOrder: string;

  @Output() change = new EventEmitter<any>();

  constructor(private toastr: ToastrService,
              private _dashboardService: CuratorDashboardService) { }
  ngOnInit() {
    this.page();
  }

  ngAfterViewInit() {
    $('#allTerms').prop('checked', 'checked');
  }

  loadOptions() {
    this.loading = true;
    let startObj = {
      key: 'start',
      value: this.offset,
      dataType: 'String'
    };

    let limitObj = {
      key: 'limit',
      value: this.limit,
      dataType: 'String'
    };
    const searchArr = [];
    this.param.map(searchObj => {
      if (searchObj['key'] !== 'start' && searchObj['key'] !== 'limit') {
        searchArr.push(searchObj);
      }
    });
    this.param = searchArr;
    this.param.push(startObj, limitObj);
    this.getTermResults();

  }

  getTermResults() {
    this._dashboardService.getSearchOptions(this.param, this.sortOrder).subscribe(
      result => this.renderOptions(result),
      err => this.errorHandler(err)
    );
  }

  filterByPreferred(option) {

    const preferredIndex = this.param.findIndex(filter => filter['key'] === 'preferred');
    this.param.splice(preferredIndex, 1);
    this.offset = 0;

    if (option !== 'all') {
      const preferredObj = {
        key : 'preferred',
        value: option === 'preferred' ? '1' : '0'
      };

      this.param.push(preferredObj);
    }
    this.page();
    this.loadOptions();
  }

  renderOptions(options) {
    this.options = options;
    this.loading = false;
    this.error = false;
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
    this.filterTypesloading = false;
  }

  page() {
    this.pageNumber = (this.offset !== 0) ? (this.offset / this.limit) + 1 : 1;
  }

  nextPage() {
    if (this.pageNumber < this.pages) {
      this.offset = this.offset + this.limit;
      this.loadOptions();
      this.page();
    }
  }

  prevPage() {
    if (this.offset > 0) {
      this.offset = this.offset - this.limit;
      this.loadOptions();
      this.page();
    }
  }

  loadSearchTypes() {
    this.filterTypesloading = true;
    let data = this.param;
    this._dashboardService.getSearchTypes(data).subscribe(
      result => this.setTypes(result),
      err => this.errorHandler(err)
    );

  }

  setTypes(types) {
    this.filterTypesloading = false;
    this.types = types;
    this.setTotal('All');
  }

  setTotal(type) {

    this.types.map((record) => {

      if (type === 'All' && record['termType'] === 'All') {
        this.totalCount = record['totalCount'];
        this.filterType = '';
      } else if (record['termTypeId'] === type) {
        this.totalCount = record['count'];
        this.filterType = record['termType'];
      }
    });

    let count = Math.ceil(this.totalCount / this.limit);
    this.pages = (this.totalCount > this.limit) ? count : 1;
  }

  setTypeFilter(type) {

    this.param.map((filter) => {
      if (filter['key'] === 'termType') {
        filter['value'] = (type === 'All') ? '' : type;
      }
    });
    this.offset = 0;
    this.setTotal(type);
    this.page();
    this.loadOptions();
  }

  ngOnChanges(changes) {
    if (changes['searchSubmitted'] && this.searchSubmitted) {
      if (changes.searchSubmitted.previousValue !== changes.searchSubmitted.currentValue) {

        if (changes.searchSubmitted.currentValue) {
          this.param = changes.searchSubmitted.currentValue;
        }
        this.pages = 1;
        this.pageNumber = 1;
        this.offset = 0;
        this.loadOptions();
        this.loadSearchTypes();
      }
    }

  }

  autoSuggestSelection(key, value, termType) {
    if (key && value && termType) {
      this.change.emit({key, value, termType});
    } else {
      this.toastr["error"]("Sorry there was some error, Please try again.");
    }
  }

  pageJump(page) {

    let pNo = page.replace(/^0+/, '');
    if (!pNo && /^[0-9]+$/.test(pNo)) {
      this.pageNumber = 1;
      this.offset = 0;
      this.loadOptions();
    }
    if (/^[0-9]+$/.test(pNo) && pNo > 0 && pNo <= this.pages) {
      this.pageNumber = pNo;
      this.offset = (pNo - 1) * this.limit;
      this.loadOptions();
    } else {
      this.toastr["warning"]("Please enter valid page number");
    }
  }


}
