import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import $ from "jquery";

@Component({
  selector: 'app-term-match',
  templateUrl: './term-match.component.html',
  styleUrls: ['./term-match.component.scss']
})
export class TermMatchComponent implements OnInit {

  modalTitle:string = 'Similar Terms Found';
  options = {
    barColor: '#12a84e',
    trackColor: '#f9f9f9',
    scaleColor: '#dfe0e0',
    scaleLength: 5,
    lineCap: 'round',
    lineWidth: 3,
    size: 30,
    rotate: 0,
    animate: {
      duration: 1000,
      enabled: true
    }
  };

  @Input() termName: any;
  @Input() matchedTerms: any;
  @Input() showModal:boolean;
  @Output() approvedToAdd = new EventEmitter();
  @Output() change = new EventEmitter();

  constructor( ) {
  }

  ngOnInit() {
    this.show();
  }

  ngOnChanges(changes) {
    if (changes) {
      this.show();
    }
  }

  show() {
    $('#proximityModel').show();
  }

  errorHandler(err) {
  }

  checkProximity(proximity) {
    let barColor = '';
    if (proximity <= 100 && proximity >= 85) {
      barColor = '#12a84e';
    } else if (proximity < 84 && proximity >= 40) {
      barColor = '#d6d606';
    } else if (proximity < 40 && proximity === 0) {
      barColor = '#ef1e25';
    }
    this.options['barColor'] = barColor;
    return true;
  }

  close() {
    this.modalChange(false);
  }

  addTerm(evt) {
    $('#proximityModel').hide();
    this.approvedToAdd.emit(this.termName);
  }

  modalChange(event) {
    $('#proximityModel').hide();
    this.showModal = false;
    this.change.emit(this.showModal);
  }

}
