import { Component, OnInit, ElementRef, ViewChild, ViewChildren } from '@angular/core';
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from "../../auth/auth.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit {

  modalRef: null;
  loginForm: FormGroup;
  submitted = false;
  title: string;
  loading: boolean;
  error: string;
  
  popupSubject : any;

  //constructor(private modalService: BsModalService,
  //  private _route: Router,
  //  private formBuilder: FormBuilder,
  //  private _authService: AuthService) {
  //    this.title = environment.app.brand.name;
  //  }
  constructor(){

  }
  ngOnInit() {

    //this.submitted = false;
    //this.loading = false;
    //this.loginForm = this.formBuilder.group({
    //  username: ['', [Validators.required, Validators.email]],
    //  password: ['', [Validators.required]]
    //});
    //
    //this.checkForActivation();

  }

  get f() {
    return this.loginForm.controls;
  }

  //checkForActivation() {
  //  this._authService.popup.subscribe(res => {
  //    this.popupSubject = res;
  //    if(res['show']) {
  //      this.openModal();
  //    }
  //
  //  });
  //}
  //
  //
  //
  //@ViewChild('template', {static: false}) elementView: ElementRef;
  //openModal() {
  //  let config = {
  //    backdrop: true,
  //    ignoreBackdropClick: true,
  //    class:'modal-dialog-centered'
  //  };
  //
  //  this.modalRef = this.modalService.show(this.elementView, config);
  //}
  //
  //get f() { return this.loginForm.controls; }
  //
  //
  //onAuthSubmit() {
  //  this.submitted = true;
  //
  //  // stop here if form is invalid
  //  if (this.loginForm.invalid) {
  //    return;
  //  }
  //
  //  this.loading = true;
  //  this._authService.onLogin(this.loginForm.value).subscribe(res => {
  //    this.loading = false;
  //
  //    if(this.popupSubject['route']) {
  //      this._route.navigate([this.popupSubject['route']])
  //    }else{
  //      window.location.reload();
  //    }
  //
  //    this._authService.setPopupSubject({
  //      show: false,
  //      route: ""
  //    });
  //
  //    this.modalService.hide(1);
  //
  //  }, err => {
  //    this.loading = false;
  //
  //  });
  //}

}
