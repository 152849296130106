import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Observer } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  termTypes:any;

  projectId: string;
  header;

  public messageSource = new BehaviorSubject('default message');

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getProjectList() {
    let userInfo = localStorage.getItem('userInfo');
    userInfo = JSON.parse(userInfo);
    let userId = userInfo['userId'];
    if (userId) {
      return this.http.get(environment.app.SERVER__URL + 'project/getList/userId/' + userId);
    }
  }

  setTermTypes(termTypes: any) {
    if(termTypes){
      this.termTypes = termTypes[0]['option'];
    }

  }

  getTermTypes() {
    return this.termTypes;
  }

  getState() {
    return this.messageSource.asObservable();
  }

}
