import $ from "jquery";

export default class Common {

 static highlight(text, search) {
    text = text.toString();

    let pattern = (search) ? search : '';
    pattern = this.escapeRegExp(pattern);
    let searchRgx = new RegExp(pattern, 'gi');
    return pattern ? text.replace(searchRgx, function (search) {
      return '<span class="highlight">' + search + '</span>';
    }) : text;
  }

  static escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }


  static addInputTag(event, form, element) {
    if (event && form && element) {
      if ($(form + " #" + element + "_join").length) {
        let value = $(form + " #" + element + "_join").val();
        if (value) {
          $(form + " #" + element + "_join").val(value + ';' + event.item);
        } else {
          $(form + " #" + element + "_join").val(event.item);
        }

        $(form + " #" + element).val(value);

        return $(form + " #" + element + "_join").val();
      }
    }
  }

  static removeInputTag(event, form, element) {
    if (event && form && element) {
      if ($(form + " #" + element + "_join").length) {
        let value = $(form + " #" + element + "_join").val();
        if (value) {
          var array = value.split(';');
          var index = array.indexOf(event.item);
          if (index > -1) {
            array.splice(index, 1);
          }

          $(form + " #" + element + "_join").val(array.join(';'));
        }

        $(form + " #" + element).val(value);

        return $(form + " #" + element + "_join").val();
      }
    }

  }


  static removeTags(html) {
    var div = document.createElement("div");
    div.innerHTML = html;
    var text = div.textContent || div.innerText || "";
    text = text.replace(/&nbsp;/gi, '');

    if (text) {
      text = text.trim();
    }

    return text;
  }

  static removeNbsp(html) {
    if (html) {
      html = this.removeUnwantedTags(html);
      return html.replace(/&nbsp;/gi, '').trim();
    } else {
      return html;
    }
  }


  static removeUnwantedTags(text) {
    if (text && text.length > 0) {
      let pblock = text.replace(/<p><br><\/p>/gi, '');
      let brRemoved = pblock.replace(/<br>/gi, '');
      let clear_p = brRemoved.replace(/<\/p>/gi, '<br>');
      let clear_br = clear_p.replace(/<p>/gi, '');
      let clearedText = clear_br.replace(/<br>$/gi, '');
      return clearedText;
    } else {
      return '';
    }
  }


  static summernotePasteResetText($textArea) {
    let self = this;
    $($textArea).on("summernote.paste", function (we, e) {
      setTimeout(function () {
        let html = $($textArea).summernote("code");
        let cleared = self.removeTags(html);
        $($textArea).summernote('code', cleared);
      }, 200);
    });
  }
}
