import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  projectId: string;
  header;
  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  downloadErrorLogs(fileType) {
    return this.http.get(environment.app.SERVER__URL + 'import/downloadErrorLog/projectId/' + this.projectId + '/logFile/' +
      fileType);
  }

  exportAllData(exportData) {

    let relationships = [];
    let termTypes = [];
    let reportType = exportData['type'] === 'excel' ? exportData['exportContent'] : 'all';
    if (exportData['exportContent'] === 'concepts') {
      termTypes = exportData['exportSubContent'];
    } else if (exportData['exportContent'] === 'relationships') {
      relationships = exportData['exportSubContent'];
    }

    let param = {
      "reportType": reportType,
      "relationType": relationships,
      "projectId": this.projectId,
      "termExport": "all",
      "termIds":[],
      "termType": termTypes,
      "exportFormat": exportData['type'],
    };
    return this.http.post(environment.app.SERVER__URL + 'export/exportData', param,  {responseType: 'blob'});
  }

  getImportEntity() {

    return this.http.get(environment.app.SERVER__URL + 'project/getTermType/projectId/' + this.projectId);
  }

  uploadFiles(formData) {
    return this.http.post(environment.app.SERVER__URL + 'import/uploadFiles/projectId/' + this.projectId, formData, this.header);
  }

  getRelationships() {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getRelations/projectId/' + this.projectId);
  }

}
