import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CuratorService} from "../curator.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  loading: boolean = false;
  entityList: any = [];
  sourceType : string = '';
  entityFileToUpload: any;
  relationFileToUpload: any;
  fileType: Array<string> = [];
  entityFileErr: boolean = false;
  relationFileErr: boolean = false;
  sourceTypeErr: boolean = false;
  formUploaded: boolean = false;

  showUploadError: boolean = false;
  importError : any;

  @Input() importOption:string;
  @Output() hidePopup = new EventEmitter();
  constructor(private _curatorService: CuratorService,  private toastr: ToastrService) { }

  ngOnInit() {
  }

  upload() {
    this.loading = true;
    this.formUploaded = true;
    if (this.entityFileToUpload && this.relationFileToUpload) {
      const formData: any = new FormData();
      const entityFile = this.entityFileToUpload;
      const relationFile = this.relationFileToUpload;
      formData.append('entity', this.entityFileToUpload);
      formData.append('relation', this.relationFileToUpload);

      formData.append('sourceType', "MCWEB");

      this._curatorService.uploadFiles(formData).subscribe(
        result => this.saveSuccess(result),
        err => this.errorHandler(err)
      );
    } else {
      this.formErrorHandler();

    }


  }

  saveSuccess(result) {
    this.loading = false;
    if (result['success'] ) {
      this.toastr['success'] (result['result']);
    } else if (!result['success'] && (!result['isRelationError'] && !result['isEntityError'])) {
      this.toastr['error'] (result ['result']);
    } else if (!result['success'] && (!result['isRelationError'] || !result['isEntityError'])) {
      this.errorHandler(result);
    }
    this.hidePopup.emit(true);
  }
  errorHandler(err) {
    this.loading = false;
    this.showUploadError = true;
    this.importError = err['error'];
   // this.toastr.error('', 'Something went wrong');
    //this.hidePopup.emit(true);
  }
  formErrorHandler() {

    if (this.formUploaded) {
      this.entityFileErr = this.entityFileToUpload.length === 0;
      this.relationFileErr = this.relationFileToUpload.length === 0;
      this.loading = false;
      //this.sourceTypeErr = this.sourceType === '';
     // this.change.emit(true);
    }

  }
  entityFileChange(fileInput: any) {
  //  this.entityFileToUpload = <Array<File>> fileInput.target.files;
    this.entityFileToUpload = (event.target as HTMLInputElement).files[0];
    this.formErrorHandler();
  }

  relationFileChange(event: any) {
  //  const file = (event.target as HTMLInputElement).files[0];
    this.relationFileToUpload = (event.target as HTMLInputElement).files[0];
    this.formErrorHandler();
  }
  downloadErrorFile(fileType) {
    this.loading = true;
    this._curatorService.downloadErrorLogs(fileType).subscribe(
      result => {
        this.loading = false;
        let blob = new Blob([result], {type: 'application/vnd.ms-excel'});
        this.downloadFile(blob, fileType + '.xlsx');
      },
      error => {
        this.loading = false;
        this.toastr["error"](error['message']);
      });
  }
  downloadFile(blobData, fileName) {
    let downloadUrl = URL.createObjectURL(blobData);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  }

}
