import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RelationsService {

  projectId: string;
  header;

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }
  getRelations(termId) {
    if (termId) {
      return this.http.get(environment.app.SERVER__URL + 'lexicon/getRelationshipInfo/projectId/' +
        this.projectId + '/termId/' + termId);
    }
  }

  getTerms(termId, rid, termType, searchTerm = 'empty', start = 0, relationValue) {
    if (termId && rid && termType) {
      let body = {
        "projectId": this.projectId,
        "termId": termId,
        "relationId": rid,
        "termType": termType,
        "searchTerm": searchTerm,
        "start": start,
        "relationValue": relationValue
      };
      return this.http.post(environment.app.SERVER__URL + 'lexicon/getRelationshipInfoWithDetails/', body, this.header);
    }
  }

  getSourceList() {
    return this.http.get(environment.app.SERVER__URL + 'cv/getSourceType/projectId/' + this.projectId + '/cvName/cv');
  }

  getSourceType(source) {
    return this.http.get(environment.app.SERVER__URL + 'cv/getCvInfo/projectId/' + this.projectId + '/cvName/' + source);
  }

  addNewRelation(data) {
    return this.http.post(environment.app.SERVER__URL + 'lexicon/addRelations', data, this.header);
  }

  deleteRelation(data) {
    return this.http.post(environment.app.SERVER__URL + 'lexicon/deleteRelations', data, this.header);
  }

  getExpectedRelations(termType) {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getRelations/projectId/' + this.projectId
      + '/termType/' + termType);
  }

  addExpectedRelation(data) {
    return this.http.post(environment.app.SERVER__URL + 'lexicon/addNewRelationForTerm', data, this.header);
  }

  deleteAllRelations(data) {
    return this.http.post(environment.app.SERVER__URL + 'lexicon/deleteAllRelations', data, this.header);
  }


  getRecentHistory(rid, formSelected) {
    let data = {
      projectId: this.projectId,
      termId: formSelected['key'],
      relationId: rid['id']
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/getRecentAddedTerms', data, this.header);
  }
  getAutosuggest(data){
    return this.http.post(environment.app.SERVER__URL + 'getSuggestTerm/search/projectId/' + this.projectId, data, this.header);
  }

}
