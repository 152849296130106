import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {SaveAsNewService} from "./save-as-new.service";
import {NewFormService} from "../new-form/new-form.service";
declare var $: any;
import {ToastrService} from "ngx-toastr";
import Common from '../../../util/common';

@Component({
  selector: 'app-save-as-new',
  templateUrl: './save-as-new.component.html',
  styleUrls: ['./save-as-new.component.scss']
})
export class SaveAsNewComponent implements OnInit {

  error:any;
  loading:boolean;
  isTermNameEmpty:boolean = false;
  editTerm: any = [];
  newTermName:string = '';
  termName:string = '';

  confirmToAddTerm:boolean = false;
  confirmTermName:any;

  saveNewTerm: any = {};
  matchedTerms: any= [];

  @Input()
  formSelected: any;

  @Output()
  newTerm = new EventEmitter<any>();

  constructor(private _saveAsNewService:SaveAsNewService,
              private _newFormService:NewFormService,
              private toastr:ToastrService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {


  }

  getEditForm() {
    this.loading = true;
    let termId = this.formSelected['key'];
    let termType = this.formSelected['termType'];
    this._saveAsNewService.getEditForm(termId, termType).subscribe(
      result => this.renderEditForm(result),
      err => this.errorHandler(err)
    );
  }

  renderEditForm(result) {
    let self = this;
    this.editTerm = result['form'];
    this.editTerm.forEach((form) => {
      if (form['attribute'] === 'name') {
        self.termName = form['formattedName'];
        self.newTermName = form['formattedName'];
      }
    });
    this.loading = false;
    this.error = false;


    $(function () {

      $('.textEditor').each(function () {
        var $textArea = this;
        $($textArea).summernote({
          disableDragAndDrop: true,
          toolbar: [
            ['style', ['bold', 'italic', 'underline']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['insert', ['specialchars']]
          ]
        });

        Common.summernotePasteResetText($textArea);

        $($textArea).on('summernote.blur', function (we, contents, $editable) {
          let ele = we.currentTarget.id;

          if (ele) {

            self.newTermName = $($textArea).summernote("code");

          }
        });

      });

    });

  }

  errorHandler(err) {
    //this.editTerm = null;
    this.error = err;
    this.loading = false;
    this.toastr["error"]("Something went wrong");
  }

  ngOnChanges(changes) {
    if (changes.formSelected.previousValue !== changes.formSelected.currentValue) {
      if (changes.formSelected.currentValue) {
        this.getEditForm();
        this.error = '';
        this.loading = false;
      }
    }
  }

  onBlurSave(value, data_field) {

    value = value.trim();

    let text = Common.removeTags(value);

    value = Common.removeNbsp(value);

    if (data_field === 'name' && text === '') {
      this.toastr["error"]("Term name can't be empty");
    } else {
      let termId = this.formSelected['key'];
      //this._saveAsNewService.saveTermAsNew(termId, data_field, value).subscribe(
      //    result => this.termUpdated(result),
      //    err => this.errorHandler(err)
      //);
    }


  }

  getMatchTerms(value) {
    this.loading = true;
    this._newFormService.getMatchedTerms(value).subscribe(
      result => this.showMatchedTerms(result, value),
      err => this.errorHandler(err)
    );
  }

  showMatchedTerms(res, term) {
    this.loading = false;
    if (res['result'].length) {
      this.matchedTerms = res['result'];
      this.confirmToAddTerm = true;
      this.confirmTermName = {termName: term};
    } else {
      this.saveTerm();
    }
  }

 approvedToAddTerm(termObj) {

    this.saveTerm();
  }

  termUpdated(result) {

    if (result && result['success']) {
      this.toastr["success"](result['message']);

      let selected = {
        key: result['cid'],
        value: Common.removeTags(result['name']),
        termType: result['termType']
      };

      $('.modal-backdrop').remove();
      this.newTerm.emit(selected);
    } else {
      this.toastr["error"](result['message']);
    }

  }

  saveAsNewTerm(form) {
    if (this.newTermName) {
      form['name'] = this.newTermName;
      this.saveNewTerm = form;
      this.getMatchTerms(form['name']);
    }
  }

  saveTerm() {
    var data = {};
    data['termId'] = this.formSelected['key'];
    Object.keys(this.saveNewTerm).forEach(key => {
      data[key] = this.saveNewTerm[key];
    });
    this._saveAsNewService.saveTermAsNew(data).subscribe(
      result => this.termUpdated(result),
      err => this.errorHandler(err)
    );
  }

}
