import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {

  modalTitle:string = 'Confirm';

  @Input() option:string;
  @Input() confirmationMsg:string;
  @Output() actionApproved = new EventEmitter();
  @Output() hidePopUp:EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }
  close(){
    this.hidePopUp.emit(true);
  }

  actionConfirmationHandler(evt) {
    let actionObj = {
      isApproved: true,
      option: this.option
    };
    this.actionApproved.emit(actionObj);

    this.close();
  }


}
