import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moreless'
})
export class MorelessPipe implements PipeTransform {

  transform(value: string, lim: number, start: number, end: any) {
    if (value && typeof value === 'string') {

      if (value && end !== 'end') {
        return value.split(' ')
          .slice(0, lim).join(' ').replace(/\xa0/g, ' ');
      } else if (value) {
        return value.split(' ')
          .slice(start, value.length).join(' ').replace(/\xa0/g, ' ');
      }
    } else {
      return value;
    }
  }

}
