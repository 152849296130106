import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  projectId:string;
  header;

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getProjectConfigStatus(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getProjectStatus/' + this.projectId);
  }
  getGenericTemplates(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getTemplateProjects');
  }
  getProjectDetails(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getDescriptionAndSKOS/' + this.projectId);
  }
  getRelation(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getRelationship/' + this.projectId);
  }

  changeProjStatus(option) {
    let param = 'pId=' + this.projectId + '&option=' + option;
    return this.http.post(environment.app.SERVER__URL + 'Manager/changeProjectstatus', param , this.header);
  }
  getTermtypesAll(){
    return this.http.get(environment.app.SERVER__URL + 'Manager/getTermTypeDetails/' + this.projectId);
  }
  getMappings() {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getRelationMapping/' + this.projectId);
  }

  getAllSearchParams() {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getSearchParam/' + this.projectId);
  }
  getIndexedItems() {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getProjectIndexes/' + this.projectId);
  }
  getConfigView() {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getFinalConfiguration/' + this.projectId);
  }
  setProjectDetails(desc, seqIdType, isSkos, shortForm){
    const param = `pId=${this.projectId}&option=${isSkos}&description=${desc}&seqIdType=${seqIdType}&projectShortForm=${shortForm}`;
    return this.http.post(environment.app.SERVER__URL + 'Manager/setDescriptionAndSKOSconfig', param, this.header);
  }
  genericTemplate(templateId, isOverride) {
    const param = `pId=${this.projectId}&templateId=${templateId}&override=${isOverride}`;
    return this.http.post(environment.app.SERVER__URL + 'Manager/copyProjectConfig', param, this.header);
  }

  getAutoSuggestRelations(data) {

    if (data) {
      let param = 'name=' + data;
      return this.http.post(environment.app.SERVER__URL + 'Manager/getRelationsAutosuggest', param, this.header);
    }
  }
  saveRelation(rel) {
    let param = 'pId=' + this.projectId + '&name=' + rel.term;
    return this.http.post(environment.app.SERVER__URL + 'Manager/addRelationship', param, this.header);
  }
  editRelation(term, id) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/editRelationship/' + this.projectId + '/' + id + '/' + term);
  }
  deleteRelation(id) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/deleteRelationship/' + this.projectId + '/' + id);
  }
  saveEditedTermType(obj) {

    let param = 'pId=' + this.projectId + '&tId=' + obj.id + '&name=' + obj.termName + '&color=' + obj.color +
      '&exptedRel=' + obj.expRel;
    return this.http.post(environment.app.SERVER__URL + 'Manager/editTermTypeExpectedRels', param, this.header);
  }

  saveTermType(obj) {

    let param = 'pId=' + this.projectId + '&name=' + obj.termName + '&color=' + obj.color +
      '&exptedRel=' + obj.expRel;
    return this.http.post(environment.app.SERVER__URL + 'Manager/addTermTypeExpectedRels', param, this.header);
  }

  getAutoSuggestTermTypes(data) {

    if (data) {
      let param = `pId=${this.projectId}&name=${data}`;
      return this.http.post(environment.app.SERVER__URL + 'Manager/getTermTypesAutosuggest', param, this.header);
    }
  }
  getAttrTermType(term) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getAllAttributes/' + this.projectId + "/" + term);
  }

  saveTermAttributes(attributes, termTypeId) {
    if (termTypeId) {
      let check = false;
      attributes.forEach(attr => {
        attr['type'] = attr.type === 'richText' ? 'textarea' : attr.type;
        if (attr.mandatory) {
          check = true;
        }
      });

      let arrAttributes = JSON.stringify(attributes);

      let param = 'pId=' + this.projectId + '&termId=' + termTypeId + '&attributeList=' + arrAttributes;
      return this.http.post(environment.app.SERVER__URL + 'Manager/addTermTypeAttributes', param, this.header);
    }
  }

  deleteTermAttribute(id, name) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/deleteTermAttribute/'+ this.projectId + '/' + id + '/' + name);
  }

  deleteTermType(id) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/deleteTerm/'+ this.projectId + '/' + id);
  }

  AddMapping(data) {

    const param = 'pId=' + this.projectId + '&termType=' + data.for_termtype.split(';')[0] +
      '&relation=' + data.for_rel + '&searchType=' + data.for_searchtype.split(';')[0] +
      '&binaryRelation=' + data.bin_rel;

    return this.http.post(environment.app.SERVER__URL + 'Manager/addRelationMapping', param, this.header);
  }
  getExpRels(term) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/getExpectedRelations/' + this.projectId + "/" + term);
  }

  deleteMap(id) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/deleteRelationMapping/' + this.projectId + '/' + id);
  }

  SaveSearch(obj) {
    let param = 'pId=' + this.projectId + '&termId=' + obj.termType + '&attribute=' + obj.ui_name + '&type=' + obj.dis_type;
    return this.http.post(environment.app.SERVER__URL + 'Manager/addSearchParam', param, this.header);
  }

  deleteSearchPar(term) {
    return this.http.get(environment.app.SERVER__URL + 'Manager/deleteSearchParam/' + this.projectId +
      '/' + term.term + '/' + term.display);
  }

  createProjectIndex(attrList) {
    const param = `pId=${this.projectId}&indexName=${attrList}`;
    return this.http.post(environment.app.SERVER__URL + 'Manager/createIndex', param, this.header);
  }

  deleteIndexedTerm(term) {
    let param = 'pId=' + this.projectId + '&index=' + term;
    return this.http.post(environment.app.SERVER__URL + 'Manager/deleteExistingIndex', param, this.header);
  }

  confirmConfig() {
    let param = 'pId=' + this.projectId;
    return this.http.post(environment.app.SERVER__URL + 'Manager/ActivateProject', param, this.header);
  }
}
