import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import {AdminService} from "../admin.service";
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  projObj:any = {};
  projects: any;
  dataLoading:boolean;

  selectedPid;
  selectedStatus: boolean;

  modalRef: BsModalRef;

  @ViewChild('f' , {static: true}) form;

  constructor(private _adminService: AdminService,
              private toastr: ToastrService,
              private modalService: BsModalService) { }

  ngOnInit() {
    localStorage.setItem('lastRoute', 'Admin');
    this.getProject();
  }

  getProject() {
    this.dataLoading = true;
    this._adminService.getProject().subscribe(res=>{
      this.projects = res;
      this.dataLoading = false;
    }, err=>{
      console.log(err);
      this.dataLoading = false;
    })
  }

  addProject(){
    this._adminService.addProject(this.projObj).subscribe(res=>{
      this.toastr.success('', 'Project added successfully!');
      this.projObj.projname = '';
      this.form.resetForm();
      this.getProject();
    }, err=>{
      console.log(err);
      this.toastr.error('', err['error']);
    })
  }

  openModal(template: TemplateRef<any>, pid , status?) {
    this.modalRef = this.modalService.show(template);
    this.selectedPid = pid;
    this.selectedStatus = status;
  }

  confirm(task): void {
    if(task == 'update'){
      this._adminService.updateStatus(this.selectedPid, this.selectedStatus).subscribe(res=>{
        this.toastr.success('', 'Project status updated successfully!');
        this.getProject();
      }, err=>{
        this.toastr.error('',  'Error in updating project!')
      })
    }
    if(task == 'delete'){
      this._adminService.deleteProj(this.selectedPid).subscribe(res=>{
        this.toastr.success('', 'Project backup/deleted successfully!');
        this.getProject();
      }, err=>{
        this.toastr.error('',  'Error in deleting project!')
      })
    }
    if(task == 'restore'){
      this._adminService.restoreProj(this.selectedPid).subscribe(res=>{
        this.toastr.success('', 'Project restored successfully!');
        this.getProject();
      }, err=>{
        this.toastr.error('',  'Error in restoring project!')
      })
    }

    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
  }

}
