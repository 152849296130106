import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {VisualizeService} from "./visualize.service";
import {CommonService} from "../../util/services/common.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-visualize',
  templateUrl: './visualize.component.html',
  styleUrls: ['./visualize.component.scss']
})
export class VisualizeComponent implements OnInit {

  objData: any;
  objInputData: any;
  preloader:boolean;
  path:boolean;
  param: any;
  types: any;
  activeProjectId: string;
  activeProjectName: string;
  projectList: any;


  constructor(private route:ActivatedRoute,
              private _visualizeService: VisualizeService,
              private toastr: ToastrService,
              private router:Router, private _commonService: CommonService) {
    this.activeProjectId = localStorage.getItem('projectId');
    this.path = false;
    this.route.queryParams.subscribe(params => {
      this.param = params;
      this.objInputData = {};
      this.objData = {
        nodes: [],
        links: []
      };
      this.types = [];

      if (params['id']) {
        this.bindRoot({
          level: 0,
          id: params['id']
        });
      }
    });
  }

  setActiveProjectName() {
    let projectList = this.projectList;
    let activeProjectId = this.activeProjectId;
    for (let project of projectList) {
      if (project.id.toString() === activeProjectId.toString()) {
        this.activeProjectName = this.ucfirst(project.value);
      }
    }
  }

  loadProjectList() {
    this._commonService.getProjectList().subscribe(
      result => this.listHandler(result)
      //err => this.errorHandler(err)
    );
  }
  listHandler(result) {
    this.projectList = result;
    this.setActiveProjectName();
  }

  ngOnInit() {
    this.loadProjectList();
  }

  bindRoot(node) {
    this.preloader = true;
    let id = node['id'];
    this._visualizeService.getChildren(id).subscribe(res => {
      this.preloader = false;

      this.objInputData = {
        id: res['id'],
        name: res['name'],
        value: 0,
        type: "term",
        children: []
      };

      res['termsRelation'].map(item => {
        this.objInputData['children'].push({
          id: item['relationId'],
          name: item['relationName'],
          value: item['totalRelationCount'],
          type: "relation"
        });
      });
    }, err => {
      this.preloader = false;
      this.toastr['error']('Error');
      this.router.navigate(['visualize']);
    });
  }
  ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}
