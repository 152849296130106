import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  @Input() show: boolean;
 // @Input() changeColor: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
