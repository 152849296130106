import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anchor'
})
export class AnchorPipe implements PipeTransform {

  transform(text:string):string {
    var expression = "(http(s)?://)+([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$";
    var regex = new RegExp(expression);

    if (text.match(regex)) {
      return `<a href="${text}" target="_blank">${text}</a>`;
    } else {
      return text;
    }
  }

}
