import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {ExportService} from "./export.service";
import {CommonService} from "../../util/services/common.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  type: any;
  exportContent: any;
  exportSubContent: any;
  loading:boolean = false;
  modalTitle:string = 'Export Term';
  authToken:string = '';
  actionUrl:string = '';
  projectId:string = '';

  isTermExport: boolean = false;
  isExcelExport: boolean = false;
  termTypes: any= [];
  exportSubType: any = [];
  relationshipTypes: any = [];

  @Input() isSkos:boolean;
  @Input() userType:string;
  @Input() formSelected: any = {};
  @Output() change = new EventEmitter();
  @Output() hidePopup = new EventEmitter();

  constructor(private _exportService: ExportService,
              private toastr: ToastrService,
              private _sharedService: CommonService ) { }

  ngOnInit() {
    this.termTypes = this._sharedService.getTermTypes();
    this.getRelationshipDetails();
  }

  getActionUrl() {
    let projectId = localStorage.getItem('projectId');
    let termId = this.formSelected['key'];
    if (termId && projectId) {
      // this.actionUrl = Config.SERVER__URL + 'user/exportToPdf/projectId/' + projectId + '/termId/' + termId;
    } else {
      //this.actionUrl = Config.SERVER__URL + 'user/exportAllPdf/projectId/' + projectId;
    }
  }

  //modalChange(event) {
  //  this.showModal = event;
  //  this.change.emit(this.showModal);
  //}

  exportData(data) {
    //console.log(data);
    if (data['type'] === 'excel') {
      if (data['type'] === '' || data['type'] === undefined
        || data['exportContent'] === '' || data['exportContent'] === undefined
        || (data['exportContent'] !== 'all' &&
        (data['exportSubContent'] === [] || data['exportSubContent'] === undefined))) {
        this.toastr["error"]('Please provide all mandatory details!');
      } else {
        this.exportFileRequest(data);
      }
    } else {
      this.exportFileRequest(data);
    }
  }

  exportFileRequest(data) {
    this.loading = true;
    this._exportService.exportAllData(data).subscribe((res) => {
      this.downloadHandler(res, data);
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.toastr["error"](err['message']);
    });
  }

  downloadHandler(res, fileOptions) {
    let blob;
    let fileName;
    if (fileOptions.type !== 'excel') {
      blob = new Blob([res], {type: 'application/rdf+xml'});
      fileName = 'skos';
    } else {
      blob = new Blob([res], {type: 'application/vnd.ms-excel'});
      fileName = fileOptions.exportContent === 'concepts' ? 'Entities' :
        (fileOptions.exportContent === 'all' ? 'All' : 'Relationships');
    }

    //let downloadUrl = URL.createObjectURL(blob);
    //let a = document.createElement("a");
    //a.href = downloadUrl;
    //a.download = fileOptions.type === 'excel' ? fileName + ".xls" : fileName + ".rdf";
    //document.body.appendChild(a);
    //a.click();

    const file = fileOptions.type === 'excel' ? fileName + ".xlsx" : fileName + ".rdf";
    this.downloadFile(blob, file);
  }

  downloadFile(blobData, fileName) {
    let downloadUrl = URL.createObjectURL(blobData);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    this.hidePopup.emit(true);
  }

  exportTypeChange(evt) {
    const exportType = evt.target.value;
    if (exportType !== 'all') {
      this.isTermExport = true;
      this.exportSubType = exportType === 'concepts' ? this.termTypes : this.relationshipTypes;
    } else {
      this.isTermExport = false;
    }
  }

  changeExportType(evt) {
    const exportOption = evt.target.value;
    this.isExcelExport = exportOption === 'excel';
  }

  getRelationshipDetails() {
    this._exportService.getRelationships().subscribe(res => {
      this.relationshipTypes = res;
    }, err => {
      this.toastr["error"](err['message']);
    });
  }

}
