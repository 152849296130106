import { Component, OnInit } from '@angular/core';
import {CuratorService} from "../curator.service";
import {ToastrService} from "ngx-toastr";
import $ from "jquery";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  error:any;
  loading:boolean;

  constructor(private _profileService: CuratorService,
              private toastr: ToastrService) {
    localStorage.setItem('lastRoute', 'profile');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  submitInfoForm(form, event) {
    event.preventDefault();
    console.log(form);
  }

  submitPasswordForm(form, event) {
    event.preventDefault();
    if (form['password'] === form['cnpassword']) {
      this.loading = true;
      this._profileService.updatePassword(form).subscribe(
        result => {
          this.loading = false;
          if (result['success']) {
            this.toastr['success']('Success', result['message']);
            $('#passwordUpdate')[0].reset();
          } else {
            this.toastr['error']('Error', result['message']);
          }
        },
        err => this.errorHandler(err)
      );

    } else {
      this.toastr['warning']("Warning", "Confirm password doesn't match");
    }
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;

    this.toastr['error']("Error", err['error']['message']);
  }

}
