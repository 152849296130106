import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CuratorDashboardService} from "./curator-dashboard.service";
declare var $: any;
import {ToastrService} from "ngx-toastr";
import {CommonService} from "../../util/services/common.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-curator-dashboard',
  templateUrl: './curator-dashboard.component.html',
  styleUrls: ['./curator-dashboard.component.scss']
})
export class CuratorDashboardComponent implements OnInit {

  error: any;
  loading: boolean;
  searchForm: Array<string>;
  autoSuggestSelected: any = {
    key: '',
    value: '',
    termType: ''
  };
  showServError: boolean = false;
  copyToGoBAck: any = {};
  advSearchPanel: boolean = false;
  searchSubmitted: any;
  formSelected: any = {};
  newFormParam: any = {};
  showHistory: boolean = false;
  termTypesArr: any;
  saveAsnewBool: Boolean = false;
  showExportBool: Boolean = false;
  quickAddTermModal: Boolean = false;
  quickAddTerm: any = {};

  isBlobActive: Boolean = false;
  blobData: any;
  termStatus: any;

  statusCode: any;
  showConfirmation: boolean = false;
  confirmationMsg: string;
  deleteType: string;
  termSelected: string;

  loadActive: any = {};
  relationValues: any = [];
  transitiveRelations: any = [];
  termColorArr: any = [];
  selectedValue: string = "";

  currentFocus:number = 0;
  suggestedValues: any = [];
  showDropDown: boolean = false;
  timer: any = 0;
  delay: number = 500;
  preLoader: boolean = false;
  searchItem: string = '';
  sortOrder: string = 'asc';


  myDatePickerOptions = {
    dateFormat: 'dd-mm-yyyy',
    editableDateField: false
  };
  modalRef:BsModalRef;
  @ViewChild('form_block', {static: true}) formBlock: ElementRef;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private toastr:ToastrService,
              private _dashboardService: CuratorDashboardService,
              private modalService:BsModalService,
              private _commonService: CommonService) {

    localStorage.setItem('lastRoute', 'dashboard');

    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.autoSuggestSelection(params['id'], params['name'], params['type']);
      }
    });
  }

  ngOnInit() {
    this.getSearchForm();
    this.getTermTypes();

    //Fetch relation values global api
    this.getRelationValues();
    //Fetch getTransitiveRelations values global api
    this.getTransitiveRelations();
    ////Fetch getTermColors values global api
    this.getTermColors();

    this.route
      .queryParams
      .subscribe(params => {
        this.autoSuggestSelection(params['key'], params['name'], params['termType']);
      });
  }
  getSearchForm() {
    this.loading = true;
    this._dashboardService.getSearchForm().subscribe(
      result => this.renderSearchForm(result),
      err => this.errorHandler(err)
    );
  }
  resetSearchForm() {
    $('.search-input-tags').tagsinput('removeAll');
}
  renderSearchForm(result) {
    this.searchForm = result;
    this.loading = false;

    const termTypes = this.searchForm.filter(form => form['attribute'] === 'termType');
    this._commonService.setTermTypes(termTypes);


    let con = this;
    $(function () {
      $('.search-input-tags').tagsinput();

      $('.search-input-tags').on('itemAdded', function (event) {
        event.preventDefault();
        var ele = event.target.dataset.attrname;
        var val = event.target.value;

        con.searchForm && con.searchForm.map(row => {
          if (row['attribute'] === ele) {
            row['value'] = val;
          }
        });
      });

      $('.search-input-tags').on('itemRemoved', function (event) {
        var ele = event.target.dataset.attrname;
        var val = event.target.value;

        con.searchForm && con.searchForm.map(row => {
          if (row['attribute'] === ele) {
            row['value'] = val;
          }
        });
      });

      $('#searchFrm').on('keyup keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
          e.preventDefault();
          return false;
        }
      });
    });
  }
  errorHandler(err) {
    this.error = err;
    this.loading = false;
    this.toastr['error'](err['error']['message']);
  }

  getTermTypes() {
    this._dashboardService.getTermTypes().subscribe(
      result => this.termTypesArr = result,
      err => this.errorHandler(err)
    );
  }
  getRelationValues() {
    this._dashboardService.getRelationValues().subscribe(
      result => {
        this.relationValues = result;
      },
      err => this.errorHandler(err)
    );
  }

  getTransitiveRelations() {
    this._dashboardService.getTransitiveRelations().subscribe(
      result => {
        this.transitiveRelations = result;
      },
      err => this.errorHandler(err)
    );
  }
  getTermColors() {
    this._dashboardService.getTermColors().subscribe(
      result => {
        this.termColorArr = result;
      },
      err => this.errorHandler(err)
    );
  }


  autoSuggestSelection(key, value, termType, hideAdvPanel = false) {
    if (key && value && termType) {

      this.autoSuggestSelected = {
        key: key,
        value: value,
        termType: termType
      };
      this.formSelected = this.autoSuggestSelected;
      this.copyToGoBAck = this.autoSuggestSelected;

      //hide new form
      this.newFormParam = {};

      //history panel if active
      if (this.showHistory) {
        this.hideHistoryPanel();
      }

      //hide Adv search panel
      if (hideAdvPanel) {
        this.advSearchPanel = false;
      }

      //save as new
      if (this.saveAsnewBool) {
        this.saveAsnewBool = false;
      }


      let recentSearch = JSON.stringify(this.autoSuggestSelected);
      if (this.autoSuggestSelected['key']) {
        localStorage.setItem('recentTerm', recentSearch);
      }

    }
  }
  hideHistoryPanel() {
    this.showHistory = false;
  }

  searchInputAutoSugg(attr, termType, event, index) {
    let val = event.target.value;
    let timer;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (val && val.length) {

        this._dashboardService.getSearchInputAutoSugg(attr, val, termType).subscribe(
          result => this.bindSearchInputAutoSugg(result, attr, index, val),
          err => this.errorHandler(err)
        );
      } else {
        $('.search-panel #' + attr).siblings('ul.auto-dropdown-options').remove();
      }
    }, 400);
  }
  bindSearchInputAutoSugg(result, attr, index, search) {
    let com = this;
    let ele = $('.search-panel #' + attr);
    let list = [];
    $.each(result, function (index, value) {
      list.push('<li><a href="javascript:void(0);">');
      list.push(com.highlight(value['value'], search));
      list.push('</a></li>');
    });

    if (ele.siblings('ul.auto-dropdown-options').length === 0) {
      $("<ul class='auto-dropdown-options'></ul>").insertAfter(ele);
    }
    if (ele.siblings('ul.auto-dropdown-options').length) {
      ele.siblings('ul.auto-dropdown-options').html(list.join(''));
    }

    ele.siblings('ul.auto-dropdown-options').on("click", "a", function (event) {
      event.preventDefault();
      let select = $(this).text();
      com['searchForm'][index]['value'] = $(this).text();
    });
  }

  onlyNumberKey(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  quickAddModalObj(quickAddTerm, template: TemplateRef<any>) {
    this.quickAddTermModal = true;
    this.quickAddTerm = quickAddTerm;
    this.openModal(template);
  }
  closeQuickTerm(event) {
    if (event === false) {
      this.quickAddTermModal = event;
      this.modalRef.hide();
      this.quickAddTerm = {};
    }
  }
  hide() {
    this.showDropDown = false;
    this.currentFocus = 0;
  }
  //
  show() {
    this.showDropDown = true;
  }

  termAutosuggest(searchPanel, event) {
    this.searchItem = searchPanel;
    let com = this;

    if (event.keyCode == 40){
      this.currentFocus++ ;
      if (this.currentFocus >this.suggestedValues.length){
        this.currentFocus = 0;
      }
      return;
    } else if (event.keyCode == 38) { //up
      if(this.currentFocus == 0){
        return
      }
      /*If the arrow UP key is pressed,
       decrease the currentFocus variable:*/
      this.currentFocus--;
      return;
    } else if (event.keyCode !== 13) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.preLoader = true;
          this.suggestedValues = [];
          let param = this.formatSearchObject(searchPanel, 'autoSuggest');
          this._dashboardService.getTermAutoSuggest(param).subscribe(res=>{
            this.suggestedValues = res;
            this.preLoader = false;
          }, err=>{
            this.preLoader = false;
            this.toastr.error('Something went wrong!')
          });

          this.show();
      }, this.delay);


    } else if(event.keyCode == 13){
      if(this.currentFocus >= 1){
        // this.keyword = this.suggestedValues[this.currentFocus-1];
        // this.keyChoosenValue.emit(this.keyword);
        // this.keyword='';
      }
      this.hide();
    } else {
      this.hide();
    }
   // let self = this;
   // let element = $("#term");
   // let query = element.val();
   // let param = this.formatSearchObject(searchPanel, 'autoSuggest');
   //// let token = localStorage.getItem('token');
   //
   // let timer;
   //
   // if (timer) {
   //   clearTimeout(timer);
   // }
   // timer = setTimeout(() => {
   //   if (query && query.length) {
   //     let list = [];
   //     if (element.siblings('span.auto-complete-loader').length === 0) {
   //       $("<span class='auto-complete-loader'>Please wait...</span>").insertAfter(element);
   //     }
   //     self._dashboardService.getTermAutoSuggest(param).subscribe((result) => {
   //       $('.auto-complete-loader').remove();
   //       if (Array.isArray(result)) {
   //         $.each(result, function (index, value) {
   //           list.push('<li>');
   //           list.push('<a href="javascript:void(0);" data-key="' + value['key'] +
   //             '" data-value="' + value['value'] + '" data-termType="' + value['termType'] + '">' +
   //             self.highlight(value['value'], query) + '</a>');
   //           list.push('</li>');
   //         });
   //       }
   //
   //
   //       if (element.siblings('ul.auto-dropdown-options').length === 0) {
   //         $("<ul class='auto-dropdown-options'></ul>").insertAfter(element);
   //       }
   //       if (element.siblings('ul.auto-dropdown-options').length) {
   //         element.siblings('ul.auto-dropdown-options').html('<li>' + list.join('') + '</li>');
   //       }
   //
   //       $("ul.auto-dropdown-options li").on("click", "a", function (event) {
   //         event.preventDefault();
   //
   //         let key = $(this).attr("data-key");
   //         let value = $(this).attr("data-value");
   //         let termType = $(this).attr("data-termType");
   //         self.autoSuggestSelection(key, value, termType, true);
   //         element.val(value);
   //       });
   //     }, (error) => {
   //       if (error['status'] === 500) {
   //         self.showServError = true;
   //       } else {
   //         $('.auto-complete-loader').html("Error: " + error['message']);
   //       }
   //     });
   //
   //   } else {
   //     element.siblings('ul.auto-dropdown-options').remove();
   //   }
   // }, 400);
  }
  onSelectedValue(list){
       event.preventDefault();
      let key = list.key;
      let value = list.value;
      let termType = list.termType;
      let element = $("#term");
      this.autoSuggestSelection(key, value, termType, true);
      element.val(value);
      this.hide();
  }

  formatSearchObject(searchObj, searchOption) {
    let self = this;
    let searchArr = [];
    Object.keys(searchObj).forEach((keyValue, ind) => {
      self.searchForm.forEach((search) => {
        if (keyValue === 'preferred' && search['attribute'] === 'preferred') {
          searchArr.push({
            key: search['attribute'],
            value: searchObj[keyValue] ? 1 : 0,
            dataType: search['dataType']
          });
        }

        if (keyValue === search['attribute'] && keyValue !== 'preferred') {
          searchArr.push({
            key: search['attribute'],
            value: searchObj[keyValue] || '',
            dataType: search['dataType']
          });
        }
      });

      if (keyValue === 'term') {
        if (searchOption === 'search') {
          self.termSelected = searchObj[keyValue];
        }
        searchArr.push({
          key: keyValue,
          value: searchObj[keyValue] || '',
          dataType: 'String',
          searchType: this.selectedValue
        });
      }
    });
    return searchArr;
  }

  submitSearch(searchPanel: any, $event): void {
    $event.preventDefault();
    let arr = {};
    let valid = false;
    Object.keys(searchPanel).forEach(function (key, index) {
      if (searchPanel[key] && searchPanel[key]['date']) {
        arr[key] = searchPanel[key]['formatted'];
      } else if (key === 'approved') {
        if (parseInt(searchPanel[key], 10) === 0) {
          arr[key] = 0;
        } else {
          arr[key] = (parseInt(searchPanel[key], 10)) ? searchPanel[key] : '';
        }
      } else {
        arr[key] = (searchPanel[key]) ? searchPanel[key] : '';
      }

      if (arr[key] !== '' || arr[key] !== '') {
        valid = true;
      }
    });

    if (valid) {
      this.searchSubmitted = this.formatSearchObject(arr, 'search');
      this.advSearchPanel = true;
    } else {
      this.toastr['warning']("Required", "Please provide atleast one search criteria");
    }

  }

  termSelection(term) {
    this.autoSuggestSelection(term.key, term.value, term.termType);
    this.formBlock.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  closeSelected() {
    this.autoSuggestSelected = {};
    this.formSelected = {};
    this.showHistory = false;

    this.newFormParam = '';
    this.saveAsnewBool = false;
  }

  backToInit() {
    this.autoSuggestSelected = this.copyToGoBAck;
    this.formSelected = this.copyToGoBAck;
    this.showHistory = false;

    this.newFormParam = '';
    this.saveAsnewBool = false;
  }

  saveAsNewTerm() {
    this.saveAsnewBool = true;

    //history panel if active
    if (this.showHistory) {
      this.hideHistoryPanel();
    }
  }
  newForm(type) {
    let projectId = localStorage.getItem('projectId');

    if (projectId) {
      this.newFormParam = {
        type: type,
        projectId: projectId
      };

      this.autoSuggestSelected = {};
    }

    //history panel if active
    if (this.showHistory) {
      this.hideHistoryPanel();
    }


    //save as new
    if (this.saveAsnewBool) {
      this.saveAsnewBool = false;
    }


  }

  showHistoryPanel() {
    //hide new form
    this.newFormParam = {};
    this.showHistory = true;

    //save as new
    if (this.saveAsnewBool) {
      this.saveAsnewBool = false;
    }
  }

  markAsDeprecated(){

  }
  showConfirmationPopup(template:TemplateRef<any>, status) {
    let label = '';
    if (status === 1) {
      label = 'Approved';
    } else if (status === 0) {
      label = 'Non Approved';
    } else if (status === -1) {
      label = 'Deprecated';
    }

    this.statusCode = status;
    this.deleteType = '';
    this.confirmationMsg = 'Are you sure want to mark this term as ' + label + ' ?';
    this.openModal(template);
  }

  openModal(template:TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  setFormSelected(form) {
    this.formSelected = form;
    this.copyToGoBAck = form;

    //hide new form
    this.newFormParam = {};

    //history panel if active
    if (this.showHistory) {
      this.hideHistoryPanel();
    }

    //save as new
    if (this.saveAsnewBool) {
      this.saveAsnewBool = false;
    }
  }

  //New term view
  setNewTermView(term) {
    this.formSelected = term;
    this.autoSuggestSelected = term;
    this.copyToGoBAck = term;

    //hide new form
    this.newFormParam = {};

    //history panel if active
    if (this.showHistory) {
      this.hideHistoryPanel();
    }

    //Save as new
    if (this.saveAsnewBool) {
      this.saveAsnewBool = false;
    }
  }

  formUpdate(status) {
    this.termStatus = status;
  }
  notesConfig(conf) {
    this.isBlobActive = true;
    this.blobData = conf;
  }

  updateStatusApproved(approvalObj) {
    this.showConfirmation = false;
    if (approvalObj['isApproved']) {
      this.updateTermStatus(this.statusCode);
    }
  }
  updateTermStatus(status) {
    //this.backToInit();
    if (this.showHistory) {
      this.showHistory = false;
    }
    let termId = this.autoSuggestSelected['key'];
    this._dashboardService.setTermStatus(termId, status).subscribe(
      result => this.statusChangeSuccess(result),
      err => this.errorHandler(err)
    );

  }
  statusChangeSuccess(result) {
    if (result['success'] === true) {
      this.toastr['success'](result['message']);

      this.autoSuggestSelected = {};
      this.formSelected = {};

    } else {
      this.toastr['error'](result['message']);
    }
  }


  highlight(text, search) {
    text = text.toString();

    let pattern = (search) ? search : '';
    pattern = this.escapeRegExp(pattern);
    let searchRgx = new RegExp(pattern, 'gi');
    return pattern ? text.replace(searchRgx, function (search) {
      return '<span class="highlight">' + search + '</span>';
    }) : text;
  }
  escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }


}
