import { Component, OnInit, TemplateRef } from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {ToastrService} from "ngx-toastr";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {CuratorService} from "./curator.service";
import {CommonService} from "../util/services/common.service";
import {Router} from '@angular/router';

@Component({
  selector: 'app-curator',
  templateUrl: './curator.component.html',
  styleUrls: ['./curator.component.scss']
})
export class CuratorComponent implements OnInit {

  projectList: any;
  loading: boolean;
  error: any;
  activeProjectName: string;
  importOption: string;
  showExportBool: Boolean = false;
  showUploadBool: Boolean = false;
  showDownloadBool: Boolean = false;
  idleTime: number = 0;
  idleLogOutTime: number = 120; //In Minute excluding 60 sec countdown
  idleCountDown: number = 60; //Last one minute
  counter: any;
  userType: string;
  isSkos: boolean;

  showErrorAlert: boolean = false;
  errorCode: string;
  errorMsg: string;

  userInfo: any;
  activeProjectId;
  releaseProject: any;

  modalRef: BsModalRef;

  constructor( private _authService: AuthService,
               private toastr: ToastrService,
               private _route: Router,
               private modalService: BsModalService,
               private _commonService: CommonService,
               private _curatorService: CuratorService) {

    this.userInfo =  this._authService.user && this._authService.user;
    this.activeProjectId = localStorage.getItem('projectId');
  }


  ngOnInit() {
    this.getUserType();
    this.loadProjectList()
  }

  onLogout() {
    this._route.navigate(['/logout']).then(() => {
      window.location.reload()
    });
  }

  getUserType() {
    this._curatorService.getUserDetails(this.userInfo['userId'], this.activeProjectId).subscribe(
      result => this.saveUserDetails(result),
      err => this.errorHandler(err)
    );
  }

  saveUserDetails(result) {
    this.userType = result['type'] || 'basic';
    this.isSkos = result['skos'] || false;
  }

  errorHandler(err) {
    this.error = err;
    this.loading = false;
  }


  loadProjectList() {
    this.loading = true;
    this._commonService.getProjectList().subscribe(
      result => this.listHandler(result),
      err => this.errorHandler(err)
    );
  }

  listHandler(result) {
    this.projectList = result;
    this.loading = false;
    this.setActiveProjectName();
  }

  setActiveProjectName() {
    let projectList = this.projectList;
    let activeProjectId = this.activeProjectId;
    for (let project of projectList) {
      if (project.id.toString() === activeProjectId.toString()) {
        this.activeProjectName = this.ucfirst(project.value);
      }
    }
  }

  setProject(projectId) {
    if (projectId !== '') {
      localStorage.setItem('projectId', projectId);
      localStorage.removeItem('recentTerm');
      window.location.href = "/#/dashboard";
      window.location.reload();
    }
  }

  openModal(template: TemplateRef<any>, proj?) {
    this.modalRef = this.modalService.show(template);
    this.releaseProject = proj;
  }

  confirm(){
    this._curatorService.setReleased(this.activeProjectName, this.releaseProject).subscribe(
      result => {
        this.toastr.success('', 'Project Released Successfully!');
        this.modalRef.hide();
      },
      err => {
        this.errorHandler(err);
        this.modalRef.hide();
      }
    );

  }

  downloadTpl(event) {

    this._curatorService.downloadTemplate().subscribe(res => {
      this.downloadFile(res, 'GenericTemplate.xlsx');
      this.modalRef.hide();
    }, err => {
      this.toastr["error"](err['message']);
      this.modalRef.hide();
    });
  }

  downloadFile(blobData, fileName) {
    let downloadUrl = URL.createObjectURL(blobData);
    let a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
  }


  ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}
