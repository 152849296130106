import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TreeService {

  projectId: string;
  header;
  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  getNodeInfo(termId) {
    return this.http.get(environment.app.SERVER__URL + 'lexicon/getTreeInfo/projectId/' + this.projectId + '/termId/' + termId);
  }

  getNodeRelationInfo(termId, rId, termType, relationValue) {

    let url = 'lexicon/getTreeInfoForRelation/projectId/' + this.projectId + '/termId/' + termId
      + '/relationId/' + rId + '/termType/' + termType + '/relationValue/' + relationValue;
    return this.http.get(environment.app.SERVER__URL + url);
  }
}
