import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import drawChart from '../../../util/galaxy.lib';
//import {isEmpty} from "rxjs/operator";
import {VisualizeService} from "../../visualize/visualize.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-galaxy-chart',
  templateUrl: './galaxy-chart.component.html',
  styleUrls: ['./galaxy-chart.component.scss']
})
export class GalaxyChartComponent implements OnInit {

  @Input() data:any;
  objData: any = {};
  termName:any;
  arrBreadcrumb: any = [];
  preLoader:boolean = false;
  index:number;

  constructor(private router:Router,
              private toastr: ToastrService,
              private _visualizeService:VisualizeService) {
    this.onClick = this.onClick.bind(this);
  }

  ngOnInit() {
  }

  init(galaxyData) {
    const chart = drawChart();
    chart.setData(galaxyData);
    chart.chart('.chart__galaxy__new', this.onClick, this.onLoadMore);
  }

  ngOnChanges(changes) {
    if (Object.keys(this.data).length) {
      if (this.data.children.length === 0) {
        this.data.children.push({
          id: '',
          name: '',
          value: 0,
          type: '',
        });
        this.arrBreadcrumb = [];
        this.init(this.data);
        //toastr['warning']('No relation found');
        //this.router.navigate(['visualize']);
      } else {
        this.arrBreadcrumb = [];
        this.init(this.data);
        this.termName = this.data['name'];
      }

    }
  }

  onClick(selected, updateCallback?) {
    this.preLoader = true;
    if (selected.data.value === 0) {
      this.preLoader = false;
      this.toastr['warning']('No realtion found');
    } else if (selected.data['type'] === 'term') {
      this.mapTermData(selected, updateCallback);

    } else if (selected.data['type'] === 'relation') {
      this.mapRelData(selected, updateCallback);
    }
  }


  mapTermData(selected, updateCallback) {
    let id = selected.data['id'];
    this._visualizeService.getChildren(id).subscribe(res => {
      this.preLoader = false;
      let node = {
        id: res['id'],
        name: res['name'],
        value: 0,
        type: "term",
        children: []
      };

      res['termsRelation'].map(item => {
        node['children'].push({
          id: item['relationId'],
          name: item['relationName'],
          value: item['totalRelationCount'],
          type: "relation",
        });
      });

      if (selected.depth === 1) {
        if (selected['reCenter'] !== true) {
          //load here
          this.mapBreadCrumb(selected);
          drawChart().addChildren(selected, node['children'], (node) => {
            updateCallback(node);
          });

        } else {
          selected.data['children'] = node['children'];
          if (selected.data['children'].length === 0 ) {
            console.log(selected.data);
            this.toastr['warning']('No realtion found');
          } else {
            this.mapBreadCrumb(selected);
            //term recenter
            const objData = node;
            this.init(objData);
          }
        }
      } else {
        selected.data['children'] = node['children'];
        if (selected.data['children'].length === 0) {
          console.log(selected.data);
          this.toastr['warning']('No realtion found');
        } else {
          this.mapBreadCrumb(selected);
          //term recenter
          const objData = node;
          this.init(objData);
        }
      }


    }, err => {
      this.toastr['error']('Error');
      this.preLoader = false;
    });

  }

//Map Relation
  mapRelData(selected, updateCallback) {
    const id = selected.parent.data['id'];
    const rel = selected.data['id'];
    this._visualizeService.getRelData(id, rel).subscribe(res => {
      this.preLoader = false;
      // Add childrens on same chart
      let arrTerms = res['termsRelation'][0]['terms'];
      let rows = [];
      arrTerms.map((item, i) => {
        if (i < 10) {
          rows.push({
            ...item,
            name: item['name'],
            id: item['id'],
            value: '1',
            type: 'term',
            children: []
          });
        }
      });
      if (selected.depth === 1) {
        if (selected['reCenter'] !== true) {
          //load here
          this.mapBreadCrumb(selected);
          drawChart().addChildren(selected, rows, (node) => {
            updateCallback(node);
          });

        } else {
          selected.data['children'] = rows;
          if (selected.data['children']) {
            this.mapBreadCrumb(selected);
            //recenter
            const objData = selected.data;
            this.init(objData);
          } else {
            this.toastr['error']('Error');
          }
        }

      } else {
        selected.data['children'] = rows;
        if (selected.data['children']) {
          this.mapBreadCrumb(selected);
          // recenter
          const objData = selected.data;
          this.init(objData);
        } else {
          this.toastr['error']('Error');
        }
      }

    }, err => {
      this.toastr['error']('Error');
      this.preLoader = false;
    });

  }

  mapBreadCrumb(selected) {
    function containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i]['data']['id'] === obj) {
          list.splice(i);
        }
      }
    }

    //function breadSelect(obj, list) {
    //    var x;
    //    for (x = list.length - 1; x >= 0; x--) {
    //        if (list[x]['data']['id'] === obj) {
    //            list.splice(x);
    //            break;
    //        }
    //    }
    //}

    if (this.data.id === selected.data.id) {
      this.setRoot();
      return;
    }
    if (selected.data.type === 'term') {
      containsObject(selected.data.id, this.arrBreadcrumb);
    }
    //if (selected.data.type === 'relation') {
    //    this.arrBreadcrumb.splice(this.index + 1);
    //}
    if ((selected.data.type === 'relation' && selected['reCenter'] && this.index !== undefined)) {
      this.arrBreadcrumb.splice(this.index + 1);
    }
    //if ((selected.data.type === 'relation' && selected['reCenter'])) {
    //    //alert('do');
    //    console.log(this.arrBreadcrumb.indexOf(selected));
    //    this.arrBreadcrumb.pop();
    //}

    const len = this.arrBreadcrumb.length;
    if (typeof(this.arrBreadcrumb[len - 1]) !== "undefined" &&
      this.arrBreadcrumb[len - 1]['data']['type'] === selected['data']['type']) {
      this.arrBreadcrumb.pop();
    }

    this.arrBreadcrumb.push(selected);
    selected['reCenter'] = true;
    this.index = undefined;
  }

  clickOnBread(bread, i) {
    this.onClick(bread);
    this.index = i;
  }
  setRoot() {
    this.init(this.data);
    this.arrBreadcrumb = [];
  }

  onLoadMore(d) {
  }

}
