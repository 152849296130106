import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlobService {

  projectId: string;
  header;

  constructor(public http: HttpClient) {
    this.projectId = localStorage.getItem('projectId');
    this.header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }

  saveBlobInfo(termId, external, attr) {
    let data = {
      name: external['name'],
      value: external['value'],
      comment: external['comment'],
      key : attr
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/addBlobInfo/' + this.projectId + '/' + termId, data, this.header);
  }

  getBlobInfo(termId, blob, start) {
    let data = {
      projectId: this.projectId,
      termId: termId,
      key : blob['attribute'],
      displayName: blob['name'],
      start : start
    };
    return this.http.post(environment.app.SERVER__URL + 'lexicon/getBlobInfo', data, this.header);
  }

  editBlobInfo(termId, blob, attr){
    const body = blob;
    body.key = attr; 
    console.log(blob)
    return this.http.put(environment.app.SERVER__URL + 'lexicon/editBlobInfo/' + this.projectId + '/' + termId + '/' + blob.id, body)
  }

  deleteBlobInfo(termId, external, attr) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        "key": attr
      },
    };
    return this.http.delete(environment.app.SERVER__URL + 'lexicon/deleteBlobInfoById/' + this.projectId + '/' + termId + '/' + external.id, options)
  }

  deleteAllBlobInfo(termId, attr){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        "key": attr
      },
    }
    return this.http.delete(environment.app.SERVER__URL + 'lexicon/deleteAllBlobInfoById/' + this.projectId + '/' + termId, options)
  }
  
}
