import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkLabel'
})
export class CheckLabelPipe implements PipeTransform {

  transform(items?:any, args?:any):any {
    return  items === 'Name' ? 'Term' : items;
  }

}
